// window.addEventListener("load", function (e) {
//   var myTabs = document.querySelectorAll(".tab__link");
//   function myTabClicks(tabClickEvent) {
//     for (let i = 0; i < myTabs.length; i++) {
//       myTabs[i].classList.remove("tab__link--active");
//       myTabs[i].setAttribute('aria-selected', 'false');
//       myTabs[i].setAttribute('tabindex', '-1');
//     }
//     const clickedTab = tabClickEvent.currentTarget;
//     clickedTab.classList.add("tab__link--active");
//     clickedTab.setAttribute('aria-selected', 'true');
//     clickedTab.setAttribute('tabindex', '0');
//     tabClickEvent.preventDefault();
//     const contentPanes = document.querySelectorAll(".tab__pane");
//     for (let i = 0; i < contentPanes.length; i++) {
//       contentPanes[i].classList.remove("tab__pane--active");
//     }
//     const anchorReference = tabClickEvent.target;
//     const activePaneId = anchorReference.getAttribute('data-tab');
//     const activePane = document.querySelector(activePaneId);
//     activePane.classList.add("tab__pane--active");
//   }
//   for (let i = 0; i < myTabs.length; i++) {
//     myTabs[i].addEventListener("click", myTabClicks)
//   }
// });

class Tabs extends HTMLElement {

  connectedCallback () {
    this.setAttribute('role', 'tablist')
    const tabs = Array.from(this.children)

    tabs.forEach((tab, i) => {
      const id = tab.getAttribute('href').replace('#', '')
      const tabpanel = document.getElementById(id)

      //Ajout attributs aria sur l'onglet
      tab.setAttribute('role', 'tab')
      tab.setAttribute('aria-selected', 'false')
      tab.setAttribute('tabindex', '-1')
      tab.setAttribute('aria-controls', id)
      tab.setAttribute('id', 'tab-' + id)

      //Ajout attributs aria sur le contenu
      tabpanel.setAttribute('role', 'tabpanel')
      tabpanel.setAttribute('aria-labelledby', 'tab-' + id)
      tabpanel.setAttribute('tabindex', '-1')

      //Navigation au clavier (Anysurfer)
      tab.addEventListener('keyup', e => {
        let index = null
        if (e.keyCode === 39) {
          index = i === tabs.length - 1 ? 0 : i + 1
        } else if (e.keyCode === 37) {
          index = i === 0 ? tabs.length - 1 : i - 1
        } else if (e.keyCode === 36) {
          index = 0
        } else if (e.keyCode === 35) {
          index = tabs.length - 1
        }
        if (index !== null) {
          this.activate(tabs[index])
          tabs[index].focus()
        }
      })
      //Navigation à la souris
      tab.addEventListener('click', e => {
        e.preventDefault()
        this.activate(tab)
      })
    })
    this.activate(tabs[0], false)
  }

  /**
   * @param {HTMLElement} tab
   * @param {boolean} changeHash
   */
  activate (tab, changeHash = true) {
    const currentTab = this.querySelector('[aria-selected="true"]')
    if (currentTab !== null) {
      const tabpanel = document.getElementById(currentTab.getAttribute('aria-controls'))
      currentTab.setAttribute('aria-selected', 'false')
      currentTab.setAttribute('tabindex', '-1')
      currentTab.classList.remove('tab__link--active')
      tabpanel.classList.remove('tab__pane--active')
      tabpanel.setAttribute('tabindex', '-1')
    }
    const id = tab.getAttribute('aria-controls')
    const tabpanel = document.getElementById(id)
    tab.setAttribute('aria-selected', 'true')
    tab.setAttribute('tabindex', '0')
    tab.classList.add('tab__link--active')
    tabpanel.classList.add('tab__pane--active')
    tabpanel.setAttribute('tabindex', '0')
    if (changeHash) {
      window.history.replaceState({}, '', '#' + id)
    }
  }
}

customElements.define('nav-tabs', Tabs)
