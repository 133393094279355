const accordion = {
  init() {
    const accordionWrappers = document.querySelectorAll('.accordion-wrapper');

    Object.keys(accordionWrappers).map((accordionWrapperIndex) => {
      let accordionWrapper = accordionWrappers[accordionWrapperIndex];
      const panelsLinks = accordionWrapper.querySelectorAll('.accordion__header');
      const panels = accordionWrapper.querySelectorAll('.accordion__item');

      let accordion = new Accordion(panelsLinks, panels);

      Object.keys(panelsLinks).map((panelsLinkIndex) => {
        let panelLink = panelsLinks[panelsLinkIndex];
        panelLink.addEventListener('click', (e) => {
          e.preventDefault();

          const target = e.target;
          accordion.accordion(target);
        });
      });

      // ADD listener on anchor links
      const anchors = accordionWrapper.querySelectorAll('.accordion__header h3');

      Object.keys(anchors).map((anchorIndex) => {
        let anchor = anchors[anchorIndex];
        let anchorId = anchor.id;
        let anchorLinks = document.querySelectorAll('[data-scrollspy=' + anchorId + ']');

        Object.keys(anchorLinks).map((anchorLinkIndex) => {
          let anchorLink = anchorLinks[anchorLinkIndex];
          anchorLink.addEventListener('click', (e) => {
            let anchorId = e.target.getAttribute('href');
            this.simulateOnClickWithH3(accordion, anchorId);
          });
        });
      });

      // ADD listener on anchor url
      document.addEventListener('DOMContentLoaded',() => {
        let anchorId = window.location.hash;
        this.simulateOnClickWithH3(accordion, anchorId);
      });
    });
  },

  simulateOnClickWithH3(accordion, querySelectorStringH3) {
    if (
      querySelectorStringH3
      && typeof querySelectorStringH3 != 'undefined'
    ) {
      let accordionLink = document.querySelector(querySelectorStringH3);

      if (
        accordionLink
        && typeof accordionLink != 'undefined'
        && accordionLink.parentNode
        && typeof accordionLink.parentNode != 'undefined'
      ) {
        accordion.accordion(accordionLink.parentNode);
      }
    }
  }

};

class Accordion {

  constructor(panelsLinks, panels) {
    this.panelsLinks = panelsLinks;
    this.panels = panels;
  }

  accordion(target) {
    Object.keys(this.panels).map((index) => {
      let elm = this.panels[index];

      if (
        target.parentNode === elm
        && !elm.classList.contains('open')
      ) {
        elm.classList.add('open');
        elm.querySelector('.accordion__header').setAttribute('aria-expanded', true);
        elm.querySelector('.accordion__body').classList.add('open');
      }
      else {
        elm.classList.remove('open');
        elm.querySelector('.accordion__header').setAttribute('aria-expanded', false);
        elm.querySelector('.accordion__body').classList.remove('open');
      }
    });
  }

}

export default accordion;
