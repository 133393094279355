import React from 'react';
import DebounceInput from 'react-debounce-input';

export default class SearchFilter extends React.Component {

  handleFilter = (e) => {
    e.preventDefault();
    // let inputVal = e.target.value;
    // this.props.handleSearchKeyword(inputVal);
  };

  render() {
    return (
      <div className="form-group searchinput">
        <DebounceInput type="text" onChange={this.handleFilter} name="keyword" id="keyword" placeholder={this.props.keyword !== '' ? this.props.keyword : 'Rechercher'} />
        <button type="submit" aria-placeholder="Rechercher"></button>
      </div>
    )
  }
}
