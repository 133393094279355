import React from 'react';

export default class ViewmodeSearch extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <article className={'resultsearch--result'}>
        <a className={'resultsearch__bloc'} target={'_blank'} href={'/' + this.props.url}>
          <h4 className={'resultsearch__title'} dangerouslySetInnerHTML={{__html: this.props.title}}></h4>
          <div className={'resultsearch__summary'} dangerouslySetInnerHTML={{__html: this.props.summary}}></div>
        </a>
      </article>
    );
  }

}
