const scrollspy = {
  init() {
    const pageContent = document.querySelector('.page-content');
    const blocks = pageContent.querySelectorAll('.block-content');
    const menuContainer = document.querySelector('.sticky-menu');
    const menu = menuContainer.querySelector('.sticky-menu__list');

    let scrollspy = new Scrollspy(pageContent, blocks, menu, menuContainer);
    window.addEventListener('scroll', () => {
      scrollspy.stickyNavScroll();
      scrollspy.scrollspy();
    });

    window.addEventListener('DOMContentLoaded', () => {
      scrollspy.stickyNavScroll();
      scrollspy.scrollToAnchor();
      scrollspy.scrollspy();
    });
  }
};

class Scrollspy {

  constructor(pageContent, blocks, menu, menuContainer) {
    this.pageContent = pageContent;
    this.blocks = blocks;
    this.menu = menu;
    this.menuContainer = menuContainer;
  }

  stickyNavScroll() {
    if (
      this.menuContainer
      && this.pageContent
    ) {
      const stickyNavObserve = this.pageContent;
      const stickyNav = this.menuContainer;
      const stickyNavContainerBottom = stickyNavObserve.offsetTop + (stickyNavObserve.offsetHeight - stickyNav.offsetHeight);


      if (window.pageYOffset > stickyNavObserve.offsetTop && window.pageYOffset < stickyNavContainerBottom) {
        if (stickyNav.classList && stickyNav.classList.contains('bottom')) {
          stickyNav.classList.remove('bottom');
        }
        else {
          stickyNav.style.maxWidth = stickyNav.parentElement.offsetWidth + 'px';
          stickyNav.classList.add('sticky');
        }
      }
      else if (window.pageYOffset >= stickyNavContainerBottom) {
        stickyNav.classList.remove('sticky');
        stickyNav.classList.add('bottom');
      }
      else {
        if (stickyNav.classList.contains('sticky')) {
          stickyNav.classList.remove('sticky');
        }
      }
    }
  }

  scrollToAnchor() {
    if (this.menu) {
      const links = document.querySelectorAll('.sticky-menu__item a');
      Object.keys(links).map((index) => {
        let link = links[index];
        link.addEventListener('click', (e) => {
          e.preventDefault();
          let anchorElement = document.querySelector(link.hash);

          anchorElement.scrollIntoView({
              behavior: 'smooth',
              block: 'start', // 'start' => hidden by sticky main menu
            });


          anchorElement.setAttribute('tabindex', -1);
          anchorElement.focus();

          const yOffset = -100;
          let y = 0;
          setTimeout(() => {
            y = anchorElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
          }, 500);

        });
      });
    }
  }

  scrollspy() {
    if (this.menu && this.blocks.length > 0) {
      Object.keys(this.blocks).map((index) => {
        let block = this.blocks[index];
        const menuElement = document.querySelector('.sticky-menu__item[data-scrollspy="${block.id}"]');
        const pageBottom = ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 20));
        const blockBottom = (block.getBoundingClientRect().bottom <= 0);
        const blockTop = (block.getBoundingClientRect().top <= 40);

        if (!menuElement) {
          return;
        }

        if (
          blockTop
          && !blockBottom
          && !pageBottom
        ) {
          if (!menuElement.classList.contains('active')) {
            menuElement.classList.add('active');
          }

          if (menuElement.classList.contains('passed')) {
            menuElement.classList.remove('passed');
          }
        }
        else if (
          blockBottom
          || pageBottom
        ) {
          if (!menuElement.classList.contains('passed')) {
            menuElement.classList.add('passed');
          }

          if (menuElement.classList.contains('active')) {
            menuElement.classList.remove('active');
          }
        }
        else {
          if (menuElement.classList.contains('active')) {
            menuElement.classList.remove('active');
          }

          if (menuElement.classList.contains('passed')) {
            menuElement.classList.remove('passed');
          }
        }
      })
    }
  }

}

export default scrollspy;
