import React from 'react';

export default class SubmitButton extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <button className={'btn btn--outline'} onSubmit={this.props.onSubmit} dangerouslySetInnerHTML={{__html: this.props.value}} {...this.props.attributes}>
      </button>
    );
  }

}
