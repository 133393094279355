import React from 'react';
import FormGroup from '../../Form/FormGroup.jsx';

export default class OptionsWithPrice extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      options: {},
      prices: {},
    };

    this._prepareField();
  }

  handleChange = (e) => {
    let value = e.target.value;

    this.props.onChange({
      preventDefault: () => {},
      target: {
        getAttribute: (att) => {
          switch (att) {
            case 'price' : return this._getTotalPrice(value);
            case 'weight' : return this.props.weight;
            case 'name' : return this.props.name;
            default : return null;
          }
        },
        value: value,
      }
    });
  };

  _prepareField = () => {
    this.props.options = this.props.options || {};
    this.props.unit_price = this.props.unit_price || 1;

    Object.keys(this.props.options).map((optionValue) => {
      let optionData = this.props.options[optionValue].split('|');
      let optionTitle = optionData[1] || null;
      let optionPrice = optionData[0] || null;

      if (
        optionValue
        && optionTitle
        && optionPrice
      ) {
        this.state.options[optionValue] = optionTitle;
        this.state.prices[optionValue] = optionPrice * this.props.unit_price;
      }
    });

    this.setState({
      options: this.state.options,
      prices: this.state.prices,
    })
  };

  _getTotalPrice = (values) => {
    let price = 0;

    Object.keys(values).map((index) => {
      let value = values[index];

      if (this.state.prices[value]) {
        price += this.state.prices[value];
      }
    });

    return price;
  };

  render() {
    this.props.type = 'checkboxes';
    let {options,onChange,...other} = this.props;

    return (
      <FormGroup options={this.state.options} onChange={this.handleChange} {...other}/>
    );
  }

}
