import React from 'react';

import ReCAPTCHA from 'react-google-recaptcha';
const recaptchaRef = React.createRef();

import FormGroup from '../Form/FormGroup.jsx';
import AddressField from './WebformElements/AddressField.jsx';
import OptionsWithPrice from './WebformElements/OptionsWithPrice.jsx';
import TotalFromOptionsWithPrice from './WebformElements/TotalFromOptionsWithPrice.jsx';

export default class WebformBuilder extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      formValues : this.props.values || {},
      elements : {},
    };
  };

  handleOnChange = (e) => {
    let formElementName = e.target.getAttribute('name');
    let formElementValue = e.target.value;

    if (
      this.state.formValues[formElementName]
      && this.state.formValues[formElementName].id == formElementName
      && this.state.formValues[formElementName].value == formElementValue
    ) {
      return;
    }

    this.state.formValues[formElementName] = {
      id: formElementName,
      value: formElementValue,
    };

    if (
      this.state.elements['total_from_options_with_price']
      && typeof this.state.elements['total_from_options_with_price'][formElementName] != 'undefined'
      && typeof this.state.elements['total_from_options_with_price'][formElementName]['value'] != 'undefined'
    ) {
      this.state.elements['total_from_options_with_price'][formElementName]['value'] = e.target.getAttribute('price');
    }

    this.setState({
      formValues: this.state.formValues,
      elements: this.state.elements,
    })
  };

  handleSubmit = (e) => {
    e.preventDefault();

    recaptchaRef.current.executeAsync()
      .then((token) => {
        if (
          token
          && typeof token == 'string'
        ) {
          this.props.handleSubmit(this.state.formValues);
        }
      });
  };

  _renderFormElement = (formElementName, formElement, weight) => {
    /* Prepare wrapper class attribute */
    formElement['#wrapper_attributes'] = formElement['#wrapper_attributes'] || {};
    formElement['#wrapper_attributes'].class = formElement['#wrapper_attributes'].class || ['col-xs-12'];
    formElement['#wrapper_attributes'] = this._buildAttributes(formElement['#wrapper_attributes']);
    formElement['#wrapper_attributes'].className = formElement['#wrapper_attributes'].className.replace(/form-error/ig, '');
    formElement['#wrapper_attributes'].className = formElement['#wrapper_attributes'].className.trim();
    if (
      this.props.errors
      && this.props.errors[formElementName]
    ) {
      formElement['#wrapper_attributes'].className+= ' form-error';
    }

    /* Prepare default value */
    formElement['#default_value'] = typeof this.props.prefill[formElementName] != 'undefined'
      ? this.props.prefill[formElementName]
      : formElement['#default_value'] || null;

    /* Prepare common data */
    let defaultProps = {
      // Props of form group
      type: formElement['#type'],
      wrapperAttributes: formElement['#wrapper_attributes'],
      description: formElement['#description'] || '',
      // Props of form group - label
      title: formElement['#title'],
      title_hidden:
        formElement['#title_display']
        && (
          formElement['#title_display'] == 'none'
          || formElement['#title_display'] == 'invisible'
        ),
      // Props of form element
      name: formElementName,
      required: formElement['#required'] || false,
      attributes: this._buildAttributes(formElement['#attributes'] || {}),
      onChange: this.handleOnChange,
      weight: weight,
      value: typeof this.state.formValues[formElementName] != 'undefined'
        ? this.state.formValues[formElementName].value
        : formElement['#default_value'],
    };

    if (defaultProps.value) {
      this.state.formValues[formElementName] = {
        id : formElementName,
        value : defaultProps.value,
      };
    }

    if (formElement['#states']) {
      let isVisible = true;

      Object.keys(formElement['#states']).map((stateType) => {
        let webformConditions = formElement['#states'][stateType][0]
          ? formElement['#states'][stateType]
          : [formElement['#states'][stateType]];

        switch (stateType) {
          case 'visible' :
            isVisible = this._webformConditionsIsTrue(webformConditions);
            break;
          case 'invisible' :
            isVisible = !this._webformConditionsIsTrue(webformConditions);
            break;
          case 'visible-slide' :
          case 'invisible-slide' :
          case 'enabled' :
          case 'disabled' :
          case 'required' :
          case 'optional' :
            break;
        }
      });

      if (!isVisible) {
        /* Clear values */
        delete this.state.formValues[formElementName];

        if (this.state.elements['total_from_options_with_price']) {
          delete this.state.elements['total_from_options_with_price'][formElementName];
        }

        return;
      }
    }

    switch (formElement['#type']) {
      case 'textfield':
      case 'email':
      case 'tel':
      case 'textarea':
        return (
          <FormGroup{...defaultProps}/>
        );
      case 'date':
        defaultProps.format = formElement['#date_date_format'];
        defaultProps.max = formElement['#date_date_max'] || '';
        defaultProps.min = formElement['#date_date_min'] || '';
        defaultProps.step = formElement['#step'] || '';

        return (
          <FormGroup{...defaultProps}/>
        );
      case 'checkboxes':
      case 'radios':
      case 'select':
        defaultProps.options = formElement['#options'];
        defaultProps.options_display = formElement['#options_display'] || 'one_column';

        return (
          <FormGroup{...defaultProps}/>
        );
      case 'checkbox':
        defaultProps.title = '';
        defaultProps.type = 'checkboxes';
        defaultProps.options = {1:formElement['#title']};

        return (
          <FormGroup{...defaultProps}/>
        );
      case 'radio':
        defaultProps.title = '';
        defaultProps.type = 'radios';
        defaultProps.options = {1:formElement['#title']};

        return (
          <FormGroup{...defaultProps}/>
        );
      case 'webform_entity_checkboxes':
      case 'webform_entity_radios':
      case 'webform_entity_select':
        defaultProps.type = defaultProps.type.replace('webform_entity_', 'entity_reference__');
        defaultProps.options_display = formElement['#options_display'] || 'one_column';
        defaultProps.options_target_site = this.props.prefix;
        defaultProps.options_bundles = typeof formElement['#selection_settings'] != 'undefined'
          && typeof formElement['#selection_settings']['target_bundles'] != 'undefined'
          ? Object.keys(formElement['#selection_settings']['target_bundles'])
          : '';

        return (
          <FormGroup{...defaultProps}/>
        );
      case 'managed_file':
      case 'webform_document_file':
      case 'webform_audio_file':
      case 'webform_image_file':
      case 'webform_video_file':
        defaultProps.type = 'file';

        return (
          <FormGroup{...defaultProps}/>
        );
      case 'webform_address':
        return (
          <AddressField
            name={formElementName}
            elements={formElement}
            required={formElement['#required']}
            onChange={this.handleOnChange}
            errors={this.props.errors}/>
        );
      case 'options_with_price':
      case 'options_with_discount':
        defaultProps.options = formElement['#options'];
        defaultProps.unit_price = formElement['#unit_price'] || 1;
        defaultProps.options_display = formElement['#options_display'] || 'one_column';

        // For TotalFromOptionsWithPrice
        this.state.elements['total_from_options_with_price'] = this.state.elements['total_from_options_with_price'] || {};
        this.state.elements['total_from_options_with_price'][formElementName] = this.state.elements['total_from_options_with_price'][formElementName] || {};
        this.state.elements['total_from_options_with_price'][formElementName]['type'] = formElement['#type'];
        this.state.elements['total_from_options_with_price'][formElementName]['value'] = this.state.elements['total_from_options_with_price'][formElementName]['value'] || 0;

        return (
          <OptionsWithPrice{...defaultProps}/>
        );
      case 'total_from_options_with_price':
        defaultProps.prices = this.state.elements['total_from_options_with_price'] || {};
        defaultProps.initial_price = formElement['#initial_price'] || 0;
        defaultProps.min_price = formElement['#min_price'] || 0;
        defaultProps.max_price = formElement['#max_price'] || -1;
        defaultProps.display = 'side_by_side';

        return (
          <TotalFromOptionsWithPrice{...defaultProps}/>
        );
      case 'markup':
      case 'webform_markup':
        defaultProps.content = (
          <div
            className={'markup'}
            dangerouslySetInnerHTML={{__html: formElement['#markup']}}
          >
          </div>
        );

        return (
          <FormGroup{...defaultProps}/>
        );
      case 'webform_horizontal_rule':
        return <div className={defaultProps.wrapperAttributes.className}></div>;
      case 'fieldset':
        defaultProps.content = (
          <>
            {
              Object.keys(formElement).map((index) => {
                if (!/^#/.test(index)) {
                  return this._renderFormElement(index, formElement[index], ++weight);
                }
              })
            }
          </>
        );

        return (
          <FormGroup{...defaultProps}/>
        );
      case 'container':
        defaultProps.wrapperAttributes.className += ' form-container';
        defaultProps.content = (
          <>
            {
              Object.keys(formElement).map((index) => {
                if (!/^#/.test(index)) {
                  return this._renderFormElement(index, formElement[index], ++weight);
                }
              })
            }
          </>
        );

        return (
          <FormGroup{...defaultProps}/>
        );
      case 'webform_actions':
        if (formElement['#submit_hide']) {
          return;
        }

        formElement['#submit__attributes'] = formElement['#submit__attributes'] || {};
        formElement['#submit__attributes'].class = formElement['#submit__attributes'].class || ['col-xs-12'];
        formElement['#submit__attributes'].class.push('form-actions');
        if (formElement['#submit_hide']) {
          formElement['#submit__attributes'].class.push('visually-hidden');
        }

        defaultProps.type = 'SubmitButton';
        defaultProps.wrapperAttributes = this._buildAttributes(formElement['#submit__attributes'] || {});
        defaultProps.wrapperAttributes.className.trim();
        defaultProps.value = formElement['#submit__label'];
        defaultProps.onSubmit = this.handleSubmit;

        return (
          <>
            <ReCAPTCHA
              ref={recaptchaRef}
              size={'invisible'}
              sitekey={'6LcLEa8ZAAAAAOco61ZkoiVq0pDPNZlhcbRiRSVc'}
            />
            <FormGroup{...defaultProps}/>
          </>
        );
    }

  };

  _buildAttributes = (webformAttributes) => {
    let renderedAttributes = {};

    Object.keys(webformAttributes).map((attributeName) => {
      let attributeValue = webformAttributes[attributeName];

      switch (attributeName) {
        case 'class' :
          renderedAttributes[attributeName] = attributeValue;
          renderedAttributes.className = attributeValue.join(' ');
          break;
        case 'style' :
          let regex = /([\w\-]*)\s*:\s*([^;]+)/g;
          let matches = {};
          let newAttributeValue = {};

          while (matches = regex.exec(attributeValue)) {
            newAttributeValue[matches[1]] = matches[2].trim();
          }

          renderedAttributes.style = newAttributeValue;
          break;
        default :
          renderedAttributes[attributeName] = attributeValue;
      }
    });

    return renderedAttributes;
  };

  _webformConditionsIsTrue(webformConditions) {
    let webformConditionsIsTrue = true;
    let webformOperation = 'and';

    Object.keys(webformConditions).map((index) => {
      if (typeof webformConditions[index] == 'string') {
        webformOperation = webformConditions[index];
      }
      else {
        Object.keys(webformConditions[index]).map((fieldName) => {
          let webformCondition = webformConditions[index][fieldName];

          if (
            webformConditions[index][fieldName].value
            && typeof webformConditions[index][fieldName].value == 'object'
          ) {
            webformCondition = webformConditions[index][fieldName].value;
          }

          switch (webformOperation) {
            case 'or' :
              webformConditionsIsTrue = webformConditionsIsTrue
                || this._webformConditionIsTrue(fieldName, webformCondition);
              break;
            case 'xor' :
              webformConditionsIsTrue = webformConditionsIsTrue
                ^ this._webformConditionIsTrue(fieldName, webformCondition);
              break;
            case 'and' :
            default :
              webformConditionsIsTrue = webformConditionsIsTrue
                && this._webformConditionIsTrue(fieldName, webformCondition);
              break;
          }
        });
      }
    });

    return webformConditionsIsTrue;
  }

  _webformConditionIsTrue(fieldName, webformCondition) {
    fieldName = fieldName.replace(/^.*\[name="(.+)"\]$/, '$1');

    let triggerType = Object.keys(webformCondition)[0];
    let triggerValue = webformCondition[triggerType];

    switch (triggerType) {
      case 'empty' :
        return (
          !this.state.formValues[fieldName]
          || !this.state.formValues[fieldName].value
        );
      case 'filled' :
        return (
          this.state.formValues[fieldName]
          && this.state.formValues[fieldName].value
        );
      case 'value' :
        return (
          this.state.formValues[fieldName]
          && this.state.formValues[fieldName].value
          && this.state.formValues[fieldName].value == triggerValue
        );
      case '!value' :
        return (
          !this.state.formValues[fieldName]
          || !this.state.formValues[fieldName].value
          || this.state.formValues[fieldName].value != triggerValue
        );
      case 'less' :
        return (
          this.state.formValues[fieldName]
          && this.state.formValues[fieldName].value
          && this.state.formValues[fieldName].value < triggerValue
        );
      case 'greater' :
        return (
          this.state.formValues[fieldName]
          && this.state.formValues[fieldName].value
          && this.state.formValues[fieldName].value > triggerValue
        );
      case 'checked' :
      case 'unchecked' :
      case 'pattern' :
      case '!pattern' :
      case 'between' :
      default:
        return true;
    }
  }

  render() {
    let weight = 0;
    let elements = Object.keys(this.props.elements).map(index => {
      weight += 1000;
      return this._renderFormElement(index, this.props.elements[index], weight);
    });

    return (
      <form className={'row'} onSubmit={this.handleSubmit} noValidate>
        {elements}
      </form>
    );
  }
}
