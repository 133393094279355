import React from 'react';

export default class InputField extends React.Component {

  constructor(props) {
    super(props);
  }

  handleChange = (e) => {
    // Do not need for input
    // e.preventDefault();

    if (this.props.onChange) {
      this.props.onChange(e);
    }
  };

  handleFocus = (e) => {
    // Do not need for input
    // e.preventDefault();

    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  };

  handleBlur = (e) => {
    // Do not need for input
    // e.preventDefault();

    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  };

  render() {
    this.props.attributes = this.props.attributes || {};
    this.props.onChange = this.props.onChange || null;

    if (typeof this.props.value != 'undefined') {
      this.props.attributes['value'] = this.props.value;
    }

    if (this.props.required) {
      this.props.attributes['required'] = 'required';
      this.props.attributes['aria-required'] = 'true';
    }

    return (
      <input
        type={this.props.type}
        name={this.props.name}
        id={this.props.id || this.props.name}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        {...this.props.attributes}/>
    );
  }

}
