import React from 'react';

import Dropzone from 'react-dropzone';

export default class FileField extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      file : null,
    };
  }

  handleChange = (acceptedFiles) => {
    let file = acceptedFiles
      && acceptedFiles[0]
      ? acceptedFiles[0]
      : null;

    if (file) {
      this._getBase64(file)
        .then(
          (b64) => {
            this.setState({
              file : file,
            });

            this.props.onChange({
              target: {
                getAttribute: (att) => {
                  switch (att) {
                    case 'name' : return this.props.name;
                    default : return null;
                  }
                },
                value: b64,
              }
            });
          }
        );
    }
    else  {
      this.setState({
        file : null,
      });

      this.props.onChange({
        preventDefault: () => {},
        target: {
          getAttribute: (att) => {
            switch (att) {
              case 'name' :
              default : return null;
            }
          },
          value: null,
        }
      });
    }
  };

  _getBase64 = (file) => {
    if (!file) {
      return false;
    }

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };

  render() {
    this.props.attributes = this.props.attributes || {};

    return (
      <Dropzone onDrop={this.handleChange}>
        {
          ({getRootProps, getInputProps}) => {
            let rootProps = getRootProps();
            rootProps.className = rootProps.className || '';
            rootProps.className += ' dropzone-wrapper border--color1 ' + (this.state.file ? 'dropzone-full' : 'dropzone-empty');
            rootProps.className = rootProps.className.trim();

            let inputProps = getInputProps();
            inputProps.id = this.props.name;
            inputProps.name = this.props.id || this.props.name;

            return (
              <div {...rootProps}>
                <input {...inputProps}/>
                <span>{this.state.file ? this.state.file.name : 'Choisir un fichier'}</span>
              </div>
            );
          }
        }
      </Dropzone>
    );
  }

}
