import React from 'react';

export default class CatalogNoResult extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      text: 'Aucun résultat n\'a été trouvé.',
    };
  }

  render() {
    return (
      <div className={'alert alert-warning'}>
        <strong>Attention !</strong> {this.props.text || this.state.text}
      </div>
    );
  }

}
