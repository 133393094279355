import React from 'react';
import ReactDOM from 'react-dom';
import Masonry from 'react-masonry-component';
import Spinner from '../Spinner.jsx';
import AjaxRequester from '../../Utils/AjaxRequester';
import ViewmodeNews from '../Viewmode/ViewmodeNews.jsx';

const masonryOptions = {
  columnWidth: '.news__wrapper',
  itemSelector: '.news__wrapper'
};

const imagesLoadedOptions = {  background: '.news__thumbnail div' };

class MasonryLayout extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      items: {},
      loaded: false,
      error: false,
      error_message: '',
    };

    this.fetchData();
  }

  fetchData() {
    let filters = {
      'ss_field_site_shortname' : {
        id: ['ss_field_site_shortname'],
        values: this.props.siteShortName,
        type: 'fq',
      },
      'index_id' : {
        id: ['index_id'],
        values: 'global_search',
        type: 'fq',
      },
      'ss_type' : {
        id: ['ss_type'],
        values: 'news',
        type: 'fq',
      },
    };

    let solrQueryParams = AjaxRequester.convertFiltersToParams(filters);

    solrQueryParams.sort = 'ds_field_news_date desc';
    solrQueryParams.rows = 6;

    AjaxRequester.getFromSolr(
      solrQueryParams,
      (ResultsData) => {
        ResultsData.response.docs = AjaxRequester.convertSolrResultsToViewExport(ResultsData, 'news');

        this.setState({
          loaded: true,
          items: ResultsData.response.docs,
        });
      },
      () => {
        this.setState({
          error: true,
          error_message: 'Une erreur s\'est produite lors du chargement du contenu.',
        })
      }
    );
  }

  render() {
    if (this.state.loaded) {
      return (
        <Masonry
          options={masonryOptions}
          imagesLoadedOptions={imagesLoadedOptions}>
          {
            Object.keys(this.state.items).map((index) => {
              return <ViewmodeNews {...this.state.items[index]}/>;
            })
          }
        </Masonry>
      );
    }
    else {
      return (
        <div>
          <Spinner/>
        </div>
      );
    }
  }
}

export default MasonryLayout;

const domContainer = document.querySelector('#news--hp');
if (domContainer) {
  const shortname = domContainer.getAttribute('data-shortname');
  ReactDOM.render(<MasonryLayout siteShortName={shortname}/>, domContainer);
}
