import React from 'react';

import {Fetcher} from '../../fetcher';
import * as CONSTANTS from '../../Utils/constants';

import Spinner from '../Spinner.jsx';

export default class ViewmodeGallery extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      status : CONSTANTS.APP_STATUS.LOADING,
      error : false,
      media_data : {},
    };

    this._fetchData();
  }

  _fetchData = () => {
    Fetcher.request('/media/image/' + this.props.thumbnail_uuid + '/thumbnail')
      .then((response) => {
        if (response.status === 'success') {
          this.setState({
            status : CONSTANTS.APP_STATUS.LOADED,
            media_data : response.query,
          });
        }
        else {
          this.setState({
            status : CONSTANTS.APP_STATUS.ERROR,
            media_data : {},
          });
        }
      });
  };

  _renderPicture = () => {
    if (this.state.status === CONSTANTS.APP_STATUS.LOADED) {
      let imgAttributes = {
        src : this.state.media_data.src,
        alt : this.props.alt || this.state.media_data.alt || null,
        title : this.state.media_data.title || null,
      };

      return (
        <img{...imgAttributes}/>
      )
    }
    else if (this.state.status === CONSTANTS.APP_STATUS.LOADING) {
      return (
        <Spinner/>
      )
    }
    else if (this.state.status === CONSTANTS.APP_STATUS.ERROR) {
      return '';
    }
  };

  _renderNbPictures = (pictures) => {
    if (
      typeof pictures != 'undefined'
      && pictures.length > 1
    ) {
      return (
        <small>
          {pictures.length} Photos
        </small>
      );
    }

    return '';
  };

  render() {
    return (
      <div className={'col-xs-6 col-md-4 col-lg-2'}>
        <a href={this.props.url} className={'gallery__grid'}>
          <figure className={'gallery__grid__img'}>
            {this._renderPicture()}
          </figure>
          <figcaption>
            {this.props.title}
            {this._renderNbPictures(this.props.photos_uri || [])}
          </figcaption>
        </a>
      </div>
    );
  }

}
