import React from 'react';
import {format} from 'date-fns';
import {fr} from 'date-fns/locale';

export default class ViewmodeMenu extends React.Component {

  constructor(props) {
    super(props);
  }

  _renderDate = () => {
    let date = new Date(this.props.menu_date);
    let formattedDate = format(date, 'eeee d MMMM', {locale: fr});
    formattedDate = formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);

    return formattedDate;
  };

  _renderDish = (dishType, dishValue) => {
    if (dishValue) {
      return (
        <div className={'menu__content__dishes menu__dishes--' + dishType} dangerouslySetInnerHTML={{__html: dishValue.join('<br/>OU<br/>')}}>
        </div>
      );
    }

    return '';
  };

  _renderContentStandard = () => {
    const ICONS = {
      'beef' : 'Un des menus proposés contient une viande.',
      'cow' : 'Un des menus proposés contient du boeuf.',
      'pig' : 'Un des menus proposés contient du porc.',
      'rooster' : 'Un des menus proposés contient une volaille.',
      'fish' : 'Un des menus proposés contient du poisson.',
      'lettuce' : 'Un des menus proposés est végétarien.',
      'cheese' : 'Un des menus proposés contient un produit laitier.',
      'gluten-free' : 'Un des menus proposés est sans gluten.',
    };

    return (
      <>
        {this._renderDish('soup', this.props.menu_soup)}
        {this._renderDish('starter', this.props.menu_starter)}
        {this._renderDish('main_course', this.props.menu_main_course)}
        {this._renderDish('dessert', this.props.menu_dessert)}
        {
          this.props.menu_icon
            ?
            <ul className={'simple horizontal'}>
              {
                Object.keys(this.props.menu_icon).map((index) => {
                  return (
                    <li>
                      <i className={'font--color1--darken-15 icon-' + this.props.menu_icon[index]}>
                        {
                          ICONS[this.props.menu_icon[index]]
                            ? <span>{ICONS[this.props.menu_icon[index]]}</span>
                            : ''
                        }
                      </i>
                    </li>
                  );
                })
              }
            </ul>
            : ''
        }
      </>
    );
  };

  _renderContentCustom = () => {
    if (this.props.body) {
      return (
        <div dangerouslySetInnerHTML={{__html: this.props.body}}></div>
      );
    }

    return '';
  };

  render() {
    return (
      <div className={'menu col-xs-12 col-sm-6 col-md-4 col-lg'}>
        <div className={'menu__block border--color1'}>
          <div className={'menu__date'}>{this._renderDate()}</div>
          <div className={'menu__content'}>
            {
              this.props.menu_type_display == 1
              ? this._renderContentStandard()
              : this._renderContentCustom()
            }
          </div>
        </div>
      </div>
    );
  }

}
