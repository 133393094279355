// SCSS
import './scss/styles.scss';

// Polyfill
import 'babel-polyfill';
import 'promise-polyfill/src/polyfill';

// Cookie
import '@wavenet/cookie-law';

// REACT COMPONENTS
import HpHeader from './js/components/Slider/HpHeader/Slider.jsx';
import HpAgenda from './js/components/Slider/HpAgenda/Slider.jsx';
import Video from './js/components/Video/Video.jsx';
import MasonryLayout from './js/components/Masonry/MasonryLayout.jsx';
import EmbeddedMedia from './js/components/Media/EmbeddedMedia.jsx';
import SvgLoadedElement from './js/components/Media/SvgLoadedElement.jsx';
import Leaflet from './js/components/Leaflet/Leaflet.jsx';
import CatalogWrapper from './js/components/Catalogs/CatalogWrapper.jsx';
import WebformWrapper from './js/components/Webform/WebformWrapper.jsx';
import OrganigrammeWrapper from './js/components/Organigramme/OrganigrammeWrapper.jsx';

// ES6 COMPONENTS
import dropdown from './js/dropdown';
import scrollspy from './js/scrollspy';
import accordion from "./js/accordion";
import mobileMenu from "./js/menu-main--mobile";
import mainMenuSticky from "./js/menu-main--sticky";
import tabs from './js/tabs';
import lightbox from "./js/lightbox";

let eltsForDropdownMainMenu = document.querySelectorAll('.menu-bar__main-menu');
Object.keys(eltsForDropdownMainMenu).map((index) => {
  let elementHtmlTag = eltsForDropdownMainMenu[index];
  dropdown.init(elementHtmlTag);
  mobileMenu.init(elementHtmlTag);
});

let eltsForMainMenuSticky = document.querySelectorAll('.header__menu-bar');
Object.keys(eltsForMainMenuSticky).map((index) => {
  let elementHtmlTag = eltsForMainMenuSticky[index];
  mainMenuSticky.init(elementHtmlTag, document.querySelector('.content__header'));
});

let eltsForDropdownSidebar = document.querySelectorAll('.sidebar__sidebar-menu');
Object.keys(eltsForDropdownSidebar).map((index) => {
  let elementHtmlTag = eltsForDropdownSidebar[index];
  dropdown.init(elementHtmlTag);
});

let eltsForScrollspy = document.querySelectorAll('.sticky-menu');
Object.keys(eltsForScrollspy).map((index) => {
  scrollspy.init();
});

let eltsForAccordion = document.querySelectorAll('.accordion-wrapper');
Object.keys(eltsForAccordion).map((index) => {
  accordion.init();
});

let eltsForLightbox1 = document.querySelectorAll('.lb_gallery__wrapper');
Object.keys(eltsForLightbox1).map((index) => {
  let elementHtmlTag = eltsForLightbox1[index];
  lightbox.init(elementHtmlTag);
});

let eltsForLightbox2 = document.querySelectorAll('.block-content.gallery');
Object.keys(eltsForLightbox2).map((index) => {
  let elementHtmlTag = eltsForLightbox2[index];
  lightbox.init(elementHtmlTag);
});
