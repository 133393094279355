import React from 'react';

import * as CONSTANTS from '../../../Utils/constants.js';
import {Fetcher} from '../../../fetcher';
import FormGroup from '../../Form/FormGroup.jsx';
import Spinner from '../../Spinner.jsx';

export default class AddressGeolookupSuggestions extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      status : 'none',
      options : [],
      wrapperStatus : 'closed',
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.searchValues != this.props.searchValues
      && (
        (
          prevProps.searchValues.q
          && this.props.searchValues.q
          && prevProps.searchValues.q != this.props.searchValues.q
        )
        || (
          prevProps.searchValues.street
          && this.props.searchValues.street
          && prevProps.searchValues.street != this.props.searchValues.street
        )
      )
    ) {
      this.setState(
        {
          status: CONSTANTS.APP_STATUS.LOADING,
        },
        () => {
          this._setOptions();
        }
      );
    }
  }

  handleChange = (e) => {
    // Do not need for radio
    // e.preventDefault();

    this.props.onChange(e);

    // do not use handleWrapperStatus to enforce closed
    this.setState({
      wrapperStatus : 'closed',
    });
  };

  handleWrapperStatus = (wrapperStatus) => {
    if (
      this.state.wrapperStatus != wrapperStatus
      && (
        wrapperStatus != 'closed'
        || this.state.status != CONSTANTS.APP_STATUS.LOADED
      )
    ) {
      this.setState({
        wrapperStatus : wrapperStatus,
      });
    }
  };

  _getNominatimQueryParams = () => {
    if (!this.props.searchValues) {
      return {};
    }

    let nominatimArgs = {};

    if (this.props.searchValues.q) {
      nominatimArgs.q = this.props.searchValues.q;
    }
    else {
      /*
        street
        city
        country
        postalcode
        countrycodes
      */
      nominatimArgs = this.props.searchValues;
    }

    nominatimArgs.addressdetails = true;
    nominatimArgs.format = 'json';
    nominatimArgs.osm_type = 'R';
    nominatimArgs.limit = 10;
    nominatimArgs['accept-language'] = 'fr';

    return nominatimArgs;
  };

  _setOptions = () => {
    let nominatimArgs = this._getNominatimQueryParams();
    let url = 'https://nominatim.openstreetmap.org/search';

    Fetcher.request(url, 'get', nominatimArgs)
      .then((response) => {
        if (
          typeof response.status !== 'undefined'
          && response.status == 'success'
        ) {

          this.setState({
            status : CONSTANTS.APP_STATUS.LOADED,
            options : this._parseDataForOptions(response.query),
            wrapperStatus : 'open',
          });
        }
        else {
          this.setState({
            status : CONSTANTS.APP_STATUS.ERROR,
            options : [],
            wrapperStatus : 'closed',
          });
        }
      });
  };

  _parseDataForOptions = (addressSuggestions) => {
    let options = {};

    Object.keys(addressSuggestions).map((index) => {
      let addressSuggestion = addressSuggestions[index].address;

      let values = {
        'address' : addressSuggestion.road || '',
        'city' : addressSuggestion.city
          || addressSuggestion.town
          || addressSuggestion.village
          || addressSuggestion.suburb
          || addressSuggestion.county
          || '',
        'country' : addressSuggestion.country_code
          ? addressSuggestion.country_code.toUpperCase()
          : '',
        'postal_code' : addressSuggestion.postcode || '',
      };

      let optionValue = JSON.stringify(values);

      if (!options[optionValue]) {
        let optionLabel = '';
        optionLabel += values.address;
        optionLabel += ' - ' + values.postal_code;
        optionLabel += ' ' + values.city;
        optionLabel += ' (' + values.country + ')';
        optionLabel = optionLabel.replace(/\s+/ig, ' ').replace(/^\s+(\-\s*)?|\s+$/ig, '');

        options[optionValue] = optionLabel;
      }
    });

    return options;
  };

  _getWrapperClassName = (wrapperClassNames) => {
    let newWrapperClassNames = {};

    // 1. Be sure unique
    // 2. Remove classes
    if (
      wrapperClassNames
      && typeof wrapperClassNames == 'string'
    ) {
      wrapperClassNames = wrapperClassNames.split(' ');

      Object.keys(wrapperClassNames).map((index) => {
        let className = wrapperClassNames[index];
        if (
          className != 'address-geolookup-suggestions--open'
          && className != 'address-geolookup-suggestions--closed'
        ) {
          newWrapperClassNames[className] = 1;
        }
      });
    }

    // 3. Add classes
    newWrapperClassNames['address-geolookup-suggestions'] = 1;
    newWrapperClassNames['address-geolookup-suggestions--' + this.state.wrapperStatus] = 1;

    return Object.keys(newWrapperClassNames).join(' ');
  };

  render() {
    this.props.wrapperAttributes = this.props.wrapperAttributes || [];
    this.props.wrapperAttributes.className = this.props.wrapperAttributes.className || '';
    this.props.wrapperAttributes.className = this._getWrapperClassName(this.props.wrapperAttributes.className);

    if (this.state.status === CONSTANTS.APP_STATUS.LOADED) {
      if (Object.keys(this.state.options).length > 0) {
        return (
          <FormGroup
            type={'radios'}
            wrapperAttributes={this.props.wrapperAttributes}
            required={false}
            name={this.props.name}
            options={this.state.options}
            onChange={this.handleChange}/>
        );
      }
      /**
       * No interest to warn there are no result if the street is not provided
       **/
      else if (
        this.props.searchValues.q
        || this.props.searchValues.street
      ) {
        return (
          <FormGroup
            wrapperAttributes={this.props.wrapperAttributes}
            content={(
              <div>
                <small>&nbsp;Pas de résultats</small>
              </div>
            )}/>
        );
      }
    }
    else if (this.state.status === CONSTANTS.APP_STATUS.LOADING) {
      return (
        <div{...this.props.wrapperAttributes}>
          <div>
            <Spinner small={true}/>
          </div>
        </div>
      );
    }
    else if (this.state.status === CONSTANTS.APP_STATUS.ERROR) {
      return (
        <FormGroup
          wrapperAttributes={this.props.wrapperAttributes}
          content={(
            <div>
              <small>&nbsp;Une erreur est survenue pendant le chargement des suggestions.</small>
            </div>
          )}/>
      );
    }

    return '';
  }

}
