import React from 'react';

import Slick from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import SliderItem from './SliderItem.jsx';

export default class SliderItems extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    let slickSettings = {
      slide : React.Fragment,
      arrows : false,
      infinite : false,
      slidesToShow : 8,
      slidesToScroll : 1,
      adaptiveHeight : false,
      responsive : [
        {
          breakpoint : 2000,
          settings : {
            slidesToShow : 6,
            slidesToScroll : 1,
          }
        },
        {
          breakpoint : 1500,
          settings : {
            slidesToShow : 5,
            slidesToScroll : 1,
          }
        },
        {
          breakpoint : 1200,
          settings : {
            slidesToShow : 4,
            slidesToScroll : 1,
          }
        },
        {
          breakpoint : 768,
          settings : {
            slidesToShow : 3,
            slidesToScroll : 1,
          }
        },
        {
          breakpoint : 576,
          settings : {
            slidesToShow : 1,
            slidesToScroll : 1,
          }
        }
      ]
    };
    let currentWidth = window.innerWidth;
    let slidesToShowForCurrentWidth = slickSettings.slidesToShow;

    Object.keys(slickSettings.responsive).map((index) => {
      let breakpoint = slickSettings.responsive[index].breakpoint || 0;
      if (currentWidth < breakpoint) {
        slidesToShowForCurrentWidth = slickSettings.responsive[index].settings.slidesToShow;
      }
    });

    return (
      <>
        {
          this.props.items.length > slidesToShowForCurrentWidth
            ? <div className={'agenda__action'}><i className={'icon-slide'}></i></div>
            : ''
        }
        <Slick {...slickSettings}>
          {
            Object.keys(this.props.items).map((index) => {
              return <SliderItem {...this.props.items[index]} />
            })
          }
        </Slick>
      </>
    );
  }

}
