import React from 'react';
import FormGroup from "../../Form/FormGroup.jsx";
import AddressGeolookupSuggestions from './AddressGeolookupSuggestions.jsx';

export default class AddressField extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      fields: {
        'country' : {
          'label' : 'Pays',
          'class' : 'col-xs-12',
          'options' : {
            'BE' : 'Belgique',
            'FR' : 'France',
          },
        },
        'address' : {
          'label' : 'Adresse',
          'class' : 'col-xs-12',
        },
        'address_2' : {
          'label' : 'Adresse (suite)',
          'class' : 'col-xs-12',
        },
        'postal_code' : {
          'label' : 'Code postal',
          'class' : 'col-xs-12 col-sm-3',
        },
        'city' : {
          'label' : 'Ville',
          'class' : 'col-xs-12 col-sm-9',
        },
        'state_province' : {
          'label' : 'État / Province',
          'class' : 'col-xs-12',
        },
      },
      search: [],
    };

    this.addressGeolookupSuggestionsRef = React.createRef();

    this._prepareFields();
  }

  _prepareFields() {
    Object.keys(this.state.fields).map((fieldName) => {

      let id = this.props.name + '][' + fieldName;

      this.state.fields[fieldName].label = this.props.elements['#' + fieldName + '__title']
        || this.state.fields[fieldName].label;
      this.state.fields[fieldName].enabled = typeof this.props.elements['#' + fieldName + '__access'] === 'undefined'
        || this.props.elements['#' + fieldName + '__access'] === true;
      this.state.fields[fieldName].id = id;
      this.state.fields[fieldName].required = this.props.required;
      this.state.fields[fieldName].value = this.state.fields[fieldName].value || '';
      this.state.fields[fieldName].attributes = {
        'data-name': fieldName,
      };
      if (this.props.errors && this.props.errors[id]) {
        this.state.fields[fieldName].class += ' form-error';
      }

      if (
        !this.state.fields[fieldName].value
        && this.props.elements['#default_value']
        && typeof this.props.elements['#default_value'][fieldName] != 'undefined'
      ) {
        this.state.fields[fieldName].value = this.props.elements['#default_value'][fieldName];
      }
    });
  }

  handleOnChange = (e) => {
    this.state.fields[e.target.getAttribute('data-name')].value = e.target.value;

    let searchValues = {
      street: this.state.fields.address
        ? this.state.fields.address.value
        : '',
      city: this.state.fields.city
        ? this.state.fields.city.value
        : '',
      country: this.state.fields.country
        ? this.state.fields.country.value
        : '',
      postalcode: this.state.fields.postal_code
        ? this.state.fields.postal_code.value
        : '',
    };

    if (!searchValues.country) {
      searchValues.countrycodes = Object.keys(this.state.fields.country.options).join(',');
    }

    // for geolookup
    this.setState(
      {
        search: searchValues,
      },
      // Saved data to webform
      this._sendDataForWebform
    );
  };

  handleAddressLookup = (e) => {
    let selectedSuggestion = JSON.parse(e.target.value);

    Object.keys(selectedSuggestion).map((fieldName) => {
      if (this.state.fields[fieldName]) {
        this.state.fields[fieldName].value = selectedSuggestion[fieldName];
      }
    });

    this.setState(
      {
        fields: this.state.fields,
      },
      // Saved data to webform
      this._sendDataForWebform
    );
  };

  _sendDataForWebform = () => {
    let valuesToSend = {};

    Object.keys(this.state.fields).map((fieldName) => {
      if (this.state.fields[fieldName].value !== '') {
        valuesToSend[fieldName] = this.state.fields[fieldName].value;
      }
    });

    this.props.onChange({
      preventDefault: () => {},
      target: {
        getAttribute: (att) => {
          switch (att) {
            case 'name' : return this.props.name;
            default : return null;
          }
        },
        value: valuesToSend,
      }
    });
  };

  handleAddressLookupOpen = (e) => {
    this.addressGeolookupSuggestionsRef.current.handleWrapperStatus('open');
  };

  handleAddressLookupClose = (e) => {
    this.addressGeolookupSuggestionsRef.current.handleWrapperStatus('closed');
  };

  render() {
    return (
      <>
        {
          Object.keys(this.state.fields).map((index) => {
            let properties = this.state.fields[index];

            if (!properties.enabled) {
              return '';
            }

            let newProperties = {
              wrapperAttributes : {'className' : properties.class},
              name : properties.id,
              title : properties.label,
              value : properties.value,
              required : this.props.required,
              onChange : this.handleOnChange,
              attributes : properties.attributes,
            };

            switch (index) {
              case 'address':
              case 'address_2':
                newProperties.type = 'textfield';
                newProperties.onFocus = this.handleAddressLookupOpen;
                newProperties.onBlur = this.handleAddressLookupClose;
                break;
              case 'city':
              case 'postal_code':
              case 'state_province':
                newProperties.type = 'textfield';
                break;

              case 'country':
                newProperties.type = 'select';
                newProperties.options = properties.options;
                break;
              default :
                return '';
            }

            if (index == 'address') {
              return (
                <>
                  <FormGroup {...newProperties}/>
                  <AddressGeolookupSuggestions
                    name={this.props.name + '[address_lookup]'}
                    wrapperAttributes={{'className' : 'col-xs-12'}}
                    searchValues={this.state.search}
                    onChange={this.handleAddressLookup}
                    ref={this.addressGeolookupSuggestionsRef}/>
                </>
              );
            }
            else {
              return (
                <FormGroup {...newProperties}/>
              );
            }
          })
        }
      </>
    );
  }

}
