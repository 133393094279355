import React from 'react';
import {addDays, format} from 'date-fns';

export const getFirstDayOfCurrentWeek = (date) => {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);

  let weekNo = date.getDay();

  return weekNo == 1
    ? date
    : addDays(date, (-weekNo + 1));
}

export const getLastDayOfCurrentWeek = (date) => {
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59);
  date.setMilliseconds(999);

  let weekNo = date.getDay();

  return weekNo == 7
    ? date
    : addDays(date, (7 - weekNo));
}
