import React from 'react';

export default class Spinner extends React.Component {

  render() {
    this.props.small = this.props.small || false;
    let className = 'spinner';
    className += this.props.small
      ? ' spinner--small'
      : '';

    return (
      <div className={className}>
        <div className={'ball'}>
          <div></div>
        </div>
      </div>
    );
  }

}
