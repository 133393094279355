import 'isomorphic-fetch';
import {Fetcher} from '../fetcher';

const AjaxRequester = {

  /* Boost some fields for searching by keyword(s) */
  APP_FILTER_BOOST : {
    'tm_X3b_fr_title' : 5,
    'tcphoneticm_X3b_fr_title' : 5,
    'tcngramm_X3b_fr_title' : 5,
    'tm_X3b_fr_body' : 2,
    'tcphoneticm_X3b_fr_body' : 2,
    'tcngramm_X3b_fr_body' : 2,
    'tm_X3b_fr_field_p_accordion_body' : 2,
    'tcphoneticm_X3b_fr_field_p_accordion_body' : 2,
    'tcngramm_X3b_fr_field_p_accordion_body' : 2,
    'tm_X3b_fr_field_p_accordion_title' : 5,
    'tcphoneticm_X3b_fr_field_p_accordion_title' : 5,
    'tcngramm_X3b_fr_field_p_accordion_title' : 5,
    'tm_X3b_fr_field_p_content_body_1' : 2,
    'tcphoneticm_X3b_fr_field_p_content_body_1' : 2,
    'tcngramm_X3b_fr_field_p_content_body_1' : 2,
    'tm_X3b_fr_field_p_content_title_1' : 5,
    'tcphoneticm_X3b_fr_field_p_content_title_1' : 5,
    'tcngramm_X3b_fr_field_p_content_title_1' : 5,
    'tm_X3b_fr_filename' : 1,
    'tcphoneticm_X3b_fr_filename' : 1,
    'tcngramm_X3b_fr_filename' : 1,
    'tm_X3b_fr_field_p_resource_description' : 1,
    'tcphoneticm_X3b_fr_field_p_resource_description' : 1,
    'tcngramm_X3b_fr_field_p_resource_description' : 1,
  },

  /**
   * Get url parameters for solr filter
   * @param filters
   */
  convertFiltersToParams : (filters) => {
    let solrQueryParams = {};
    let q = [];
    let fq = [];
    let needScore = false;

    Object.keys(filters).map((filterIndex) => {
      let filter = filters[filterIndex];
      let queryString = [];

      if (typeof filter.values !== 'object') {
        filter.values = [filter.values];
      }

      Object.keys(filter.id).map((idIndex) => {
        let solrFieldName = filter.id[idIndex];

        Object.keys(filter.values).map((valueIndex) => {
          let condition = '';
          let filterValue = filter.values[valueIndex];

          if (
            filterValue.indexOf('[') !== -1
            || filterValue.indexOf('(') !== -1
            || filterValue == '*'
          ) {
            condition = solrFieldName + ':' + filterValue;
          }
          else if (filterValue !== '') {
            condition = solrFieldName + ':"' + filterValue + '"';
          }
          else {
            condition = '-' + solrFieldName + ':*';
          }

          if (AjaxRequester.APP_FILTER_BOOST[solrFieldName]) {
            condition += '^' + AjaxRequester.APP_FILTER_BOOST[solrFieldName];
            needScore = true;
          }

          queryString.push(condition);
        });
      });

      queryString = '(' + queryString.join(' OR ') + ')';

      if (filter.type == 'fq') {
        fq.push(queryString);
      }
      else {
        q.push(queryString);
      }
    });

    // Exposed filters
    solrQueryParams.q = q.length >= 1
      ? q.join(' AND ')
      : '*';

    // Basic filters
    if (fq.length >= 1) {
      solrQueryParams.fq = fq.join(' AND ');
    }

    if (needScore) {
      solrQueryParams.fl = 'score,*';
      solrQueryParams.defType = 'edismax';
    }

    return solrQueryParams;
  },

  /**
   * Get from Solr
   * @param args
   * @param success
   * @param error
   */
  getFromSolr : (args = {}, success, error) => {
    AjaxRequester._get(
      '/solr-api',
      args,
      success,
      error
    );
  },

  /**
   * Basic Get
   *
   * @param url
   * @param args
   * @param success
   * @param error
   * @private
   */
  _get(url, args = {}, success, error) {
    try {
      Fetcher.request(url, 'get', args)
        .then((response) => {
          if (
            typeof response.status !== 'undefined'
            && response.status == 'success'
          ) {
            success(response.query);
          }
          else {
            error();
          }
        });
    }
    catch (error) {
      error();
    }
  },

  /**
   * Convert Solr results in human readable objects
   *
   * @param solrResults
   * @param type
   * @returns {[]}
   */
  convertSolrResultsToViewExport: (solrResults, type) => {
    let resultsParsed = [];

    for (let result of solrResults.response.docs) {
      let resultParsed = {
        url : result.sm_url
          || result.ss_search_api_id.replace('entity:', '').replace(':fr', ''),
        title : result.tm_X3b_fr_title
          || result.tcphoneticm_X3b_fr_title
          || result.tcngramm_X3b_fr_title
          || null,
        summary : result.tcngramm_X3b_fr_summary_processed
          || null,
        body : result.tm_X3b_fr_body
          || result.tcphoneticm_X3b_fr_body
          || result.tcngramm_X3b_fr_body
          || null,
        thumbnail_uri : result.ss_thumbnail_url
          || null,
        thumbnail_uuid : result.ss_thumbnail_uuid
          || null,
        banner_uri : result.ss_banner_url
          || null,
      };

      if (
        resultParsed.body
        && resultParsed.body[0]
        && typeof resultParsed.body[0] != 'undefined'
      ) {
        resultParsed.body = resultParsed.body[0];
      }

      if (resultParsed.summary === null) {
        resultParsed.summary = resultParsed.body;
      }

      switch (type) {
        case 'hp_slider' :
          resultParsed.slide_image = result.zm_field_p_slide_image_url;
          break;
        case 'agenda' :
        case 'event' :
          resultParsed.start_date = result.ds_field_event_daterange;
          resultParsed.end_date = result.ds_end_value;
          break;
        case 'news' :
          resultParsed.date = result.ds_field_news_date;
          resultParsed.tags = result.zm_field_news_tags;
          break;
        case 'faq' :
          resultParsed.tags = result.sm_faq_tags;
          resultParsed.id = 'n-' + result.its_nid;
          resultParsed.mediaUuid = result.zm_field_p_media_uuid;
          resultParsed.mediaType = result.zm_field_p_media_bundle;
          break;
        case 'menu' :
          resultParsed.menu_date = result.ds_field_menu_date;
          resultParsed.menu_type_display = result.its_field_menu_type_display;
          resultParsed.menu_soup = result.tm_X3b_fr_field_menu_soup;
          resultParsed.menu_starter = result.tm_X3b_fr_field_menu_starter;
          resultParsed.menu_main_course = result.tm_X3b_fr_field_menu_main_course;
          resultParsed.menu_dessert = result.tm_X3b_fr_field_menu_dessert;
          resultParsed.menu_icon = result.sm_field_menu_icon;
          break;
        case 'gallery' :
          resultParsed.photos_uri = result.sm_gallery_photos_uri;
          break;
        case 'search' :
            resultParsed.mimetype = '';
            resultParsed.type = result.ss_type || null;
            resultParsed.nid = result.its_nid || null;
            resultParsed.title = solrResults.highlighting[result.id]['tcngramm_X3b_fr_title']
              || resultParsed.title;
            resultParsed.summary = solrResults.highlighting[result.id]['tcngramm_X3b_fr_summary_processed']
              || solrResults.highlighting[result.id]['tcngramm_X3b_fr_body']
              || resultParsed.summary;
            if (typeof resultParsed.summary === 'string' ) {
              resultParsed.summary = resultParsed.summary.replace(/<\/?p[^>]*>/g, '');
            }
            else if (resultParsed.summary !== null && resultParsed.summary[0] ) {
              resultParsed.summary[0] = resultParsed.summary[0].replace(/<(<span[^>]*>)?\/?p[^>]*>/g, '$1');
            }
          break;
      }

      resultsParsed.push(resultParsed);
    }

    return resultsParsed;
  },

};

export default AjaxRequester;
