import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import SliderItems from './SliderItems.jsx';
import Spinner from '../../Spinner.jsx';
import AjaxRequester from '../../../Utils/AjaxRequester';

class Slider extends Component {

  constructor(props) {
    super(props);

    this.state = {
      items: {},
      loaded: false,
      error: false,
      error_message: '',
    };

    this.fetchData();
  }

  fetchData() {
    let filters = {
      'ss_field_site_shortname' : {
        id: ['ss_field_site_shortname'],
        values: this.props.siteShortName,
        type: 'fq',
      },
      'index_id' : {
        id: ['index_id'],
        values: 'global_search',
        type: 'fq',
      },
      'ss_type' : {
        id: ['ss_type'],
        values: 'evenement',
        type: 'fq',
      },
      'ds_end_value' : {
        id: ['ds_end_value'],
        values: '[' + (new Date()).toISOString() + ' TO *]',
        type: 'fq',
      },
    };

    let solrQueryParams = AjaxRequester.convertFiltersToParams(filters);

    solrQueryParams.sort = 'ds_field_event_daterange asc';
    solrQueryParams.rows = 24;

    AjaxRequester.getFromSolr(
      solrQueryParams,
      (ResultsData) => {
        ResultsData.response.docs = AjaxRequester.convertSolrResultsToViewExport(ResultsData, 'event');

        this.setState({
          loaded: true,
          items: ResultsData.response.docs,
        });
      },
      () => {
        this.setState({
          error: true,
          error_message: 'Une erreur s\'est produite lors du chargement du contenu.',
        })
      }
    );
  }

  render() {
    if (this.state.error) {
      return (
        <p>{this.state.error_message}</p>
      );
    }
    else if (this.state.loaded && this.state.items) {
      return (
        <SliderItems items={this.state.items}/>
      );
    }
    else {
      return (
        <div className={'agenda__wrapper agenda__wrapper--not-loaded'}>
          <Spinner/>
        </div>
      );
    }
  }

}

export default Slider;

const domContainer = document.querySelector(".agenda__slider");
if (domContainer) {
  const shortname = domContainer.getAttribute('data-shortname');
  ReactDOM.render(<Slider siteShortName={shortname}/>, domContainer);
}
