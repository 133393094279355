import React from 'react';
import EmbeddedMedia from '../Media/EmbeddedMedia.jsx';

export default class OrganigrammeItem extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={'organigramme--item row'}>
        {
          this.props.picture
            ? <EmbeddedMedia uuid={this.props.picture} imageStyle={'embed_wysiwyg_image'}/>
            : ''
        }
        <div className={'organigramme--item--content col-xs-12'}>
          {
            this.props.title
              ? <h4 className={'organigramme--item--title'} dangerouslySetInnerHTML={{__html: this.props.title}}></h4>
              : ''
          }
          {
            this.props.body
              ? <div className={'organigramme--item--description'} dangerouslySetInnerHTML={{__html: this.props.body}}></div>
              : ''
          }
        </div>
      </div>
    );
  }

}
