import React from 'react';

import SelectField from './SelectField.jsx';
import CheckboxesField from './CheckboxesField.jsx';
import RadiosField from './RadiosField.jsx';
import Spinner from '../../Spinner.jsx';

import AjaxRequester from '../../../Utils/AjaxRequester';
import * as CONSTANTS from '../../../Utils/constants';

export default class EntityReferenceField extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      filters: {},
      sort: {},
      max: {},
      options: {},
      status: CONSTANTS.APP_STATUS.LOADING,
    };
  }

  componentDidMount() {
    this.setState({
      status: CONSTANTS.APP_STATUS.LOADING
    });

    this._setOptions();
  }

  _setFilterForGettingOptions = () => {
    this.state.filters.index_id = {
      id: ['index_id'],
      values: 'global_search',
      type: 'fq',
    };

    this.state.filters.ss_field_site_shortname = {
      id: ['ss_field_site_shortname'],
      values: this.props.options_target_site,
      type: 'fq',
    };

    if (this.props.options_bundles) {
      this.state.filters.ss_type = {
        id: ['ss_type'],
        values: '(' + this.props.options_bundles.join(' OR ') + ')',
        type: 'fq',
      };
    }
  };

  _setSortForGettingOptions = () => {
    this.state.sort = 'sort_X3b_fr_title asc';
  };

  _setMaxForGettingOptions = () => {
    this.state.max = 500;
  };

  _convertSolrResultsToOption = (results) => {
    let options = {};

    results.map((result) => {
      options[result.its_nid] = result.tcngramm_X3b_fr_title;
    });

    return options;
  };

  _setOptions = () => {
    this._setFilterForGettingOptions();
    this._setSortForGettingOptions();
    this._setMaxForGettingOptions();

    let solrQueryParams = AjaxRequester.convertFiltersToParams(this.state.filters);
    solrQueryParams.sort = this.state.sort;
    solrQueryParams.rows = this.state.max;

    AjaxRequester.getFromSolr(
      solrQueryParams,
      (ResultsData) => {
        this.setState({
          options : this._convertSolrResultsToOption(ResultsData.response.docs),
          status : CONSTANTS.APP_STATUS.LOADED,
        });
      },
      () => {
        this.setState({
          options: {},
          status: CONSTANTS.APP_STATUS.ERROR
        });
      }
    );
  };

  render() {
    if (this.state.status === CONSTANTS.APP_STATUS.LOADED) {
      this.props.options = this.state.options;

      let {type,...other} = this.props;

      switch (this.props.type) {
        case 'select' :
          return <SelectField{...other}/>;
        case 'checkboxes' :
          return <CheckboxesField{...other}/>;
        case 'radios' :
          return <RadiosField{...other}/>;
      }
    }
    else if (this.state.status === CONSTANTS.APP_STATUS.LOADING) {
      return (
        <Spinner small={true} />
      );
    }
    else if (this.state.status === CONSTANTS.APP_STATUS.ERROR) {
      return (
        <small>
          Une erreur s'est produite durant le chargement des options.
        </small>
      );
    }
  }

}
