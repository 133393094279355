import React from 'react';
import ViewmodeMenu from './ViewmodeMenu.jsx';

export default class ViewmodeMenuPrint extends ViewmodeMenu {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <td className={'menu__block border--color1'}>
        <div className={'menu__date'}>{this._renderDate()}</div>
        <div className={'menu__content'}>
          {
            this.props.menu_type_display == 1
              ? this._renderContentStandard()
              : this._renderContentCustom()
          }
        </div>
      </td>
    );
  }

}
