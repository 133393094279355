const CatalogRows = {

  getRowsNumber : (type) => {
    switch (type) {
      case 'search' :
        return 10;
      case 'agenda' :
      case 'news' :
      case 'faq' :
      case 'gallery' :
        return 1000;
      default :
        return 0;
    }
  },

};

export default CatalogRows;
