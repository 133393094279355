import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Spinner from '../../Spinner.jsx';
import AjaxRequester from '../../../Utils/AjaxRequester';

import SliderItems from './SliderItems.jsx';
import SliderItem from './SliderItem.jsx';

class Slider extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      error: false,
      error_message: "",
      items: {},
    };

    this.fetchData();
  }

  fetchData() {
    let filters = {
      'ss_field_site_shortname' : {
        id: ['ss_field_site_shortname'],
        values: this.props.siteShortName,
        type: 'fq',
      },
      'index_id' : {
        id: ['index_id'],
        values: 'additional_data',
        type: 'fq',
      },
      'ss_type' : {
        id: ['ss_type'],
        values: 'homepage',
        type: 'fq',
      },
    };

    let solrQueryParams = AjaxRequester.convertFiltersToParams(filters);

    AjaxRequester.getFromSolr(
      solrQueryParams,
      (ResultsData) => {
        ResultsData.response.docs = AjaxRequester.convertSolrResultsToViewExport(ResultsData, 'hp_slider');

        let items = {};

        if (
          ResultsData.response.docs
          && ResultsData.response.docs[0]
          && ResultsData.response.docs[0].slide_image
        ) {
          Object.keys(ResultsData.response.docs[0].slide_image).map((index) => {
            items[index] = {
              'slide_image' : ResultsData.response.docs[0].slide_image[index],
            };
          });
        }

        this.setState({
          loaded: true,
          items: items,
        });
      },
      () => {
        this.setState({
          error: true,
          error_message: 'Une erreur s\'est produite lors du chargement du contenu.',
        })
      }
    );
  }

  render() {
    if (this.state.error) {
      return (
        <p>{this.state.error_message}</p>
      );
    }
    else if (this.state.loaded) {
      return (
        <SliderItems items={this.state.items}/>
      );
    }
    else {
      return (
        <div className="container__slides-wrapper slider__slide-wrapper--not-loaded">
          <Spinner/>
        </div>
      );
    }
  }

}

export default Slider;

const domContainer = document.querySelector('.hp-slider');
if (domContainer) {
  const shortname = domContainer.getAttribute('data-shortname');
  ReactDOM.render(<Slider siteShortName={shortname}/>, domContainer);
}

