import React from 'react';
import ReactDOMServer from 'react-dom/server';
import {saveAs} from 'file-saver';

import {Fetcher} from '../../fetcher';
import * as CONSTANTS from '../../Utils/constants';
import Spinner from '../Spinner.jsx';

export default class ExportToPdfButton extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      status: CONSTANTS.APP_STATUS.LOADED,
    };
  }

  handleClick = (e) => {
    e.preventDefault();

    this.setState(
      {
        status: CONSTANTS.APP_STATUS.LOADING,
      },
      this._fetchPdf
    );
  };

  _fetchPdf = () => {
    try {
      let postArgs = {
        path : window.location.href,
        fileName : this.props.fileName,
        fileTitle : this.props.fileTitle,
        html : ReactDOMServer.renderToString(this.props.fileContent) || null,
      };

      Fetcher.request('/media/print', 'post', postArgs)
        .then((response) => {
          if (
            typeof response.status !== 'undefined'
            && response.status == 'success'
          ) {
            saveAs(response.query, postArgs.fileName + '.pdf');

            this.setState({
              status: CONSTANTS.APP_STATUS.LOADED,
            });
          }
          else {
            this.setState({
              status: CONSTANTS.APP_STATUS.ERROR,
            });
          }
        });
    }
    catch (error) {
      this.setState({
        status: CONSTANTS.APP_STATUS.ERROR,
      });
    }
  };

  render() {
    this.props.attributes = this.props.attributes || {};
    this.props.attributes.className = this.props.attributes.className || '';
    this.props.attributes.className += ' btn--export-pdf icon-pdf font--color1--darken-15';
    this.props.attributes.className += this.props.attributes.className.trim();

    if (this.state.status === CONSTANTS.APP_STATUS.LOADING) {
      return (
        <button onClick={this.handleClick} {...this.props.attributes}>
          <Spinner small={true}/>
        </button>
      );
    }
    else if (
      this.state.status === CONSTANTS.APP_STATUS.LOADED
      || this.state.status === CONSTANTS.APP_STATUS.ERROR
    ) {
      return (
        <button onClick={this.handleClick} {...this.props.attributes}>
          Télécharger
        </button>
      );
    }
  }

}
