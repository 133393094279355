const lightbox = {
  init: (HtmlElement, SelectorElement = 'a') => {
    return new GalleryLightBox(HtmlElement, SelectorElement);
  }
};

class GalleryLightBox {

  /**
   * Constructor for GalleryLightbox
   * @param HtmlElement
   * @param SelectorElement
   */
  constructor(HtmlElement, SelectorElement) {
    this.openers = HtmlElement.querySelectorAll(SelectorElement);

    [...this.openers].map(opener => {
      opener.addEventListener('click', e => {
        this._openModal(e);
      })
    });

    this.slideIndex = 0;
  }

  /**
   * Inject modal in Dom with current image selection
   * @param e
   * @private
   */
  _openModal = (e) => {
    e.preventDefault();
    let triggeredElementId = e.target.getAttribute('data-slide');
    let body = document.getElementsByTagName('body');
    let modal = this._renderModal();
    modal.classList.add('lightbox__modal--open');
    [...body].map( el => {el.appendChild(modal)});
    this._showSlide(parseInt(triggeredElementId));
  };

  /**
   * Close modal
   * @param e
   * @param modal
   * @private
   */
  _closeModal = (e, modal) => {
    e.preventDefault();
    modal.remove();
  };

  /**
   * Show specific item
   * @param n
   * @private
   */
  _showSlide = (n) => {
    let slides = document.querySelectorAll('.lightbox__slides');
    [...slides].map(slide => {
      slide.style.display = 'none'
    });
    this.slideIndex = n;
    if (this.slideIndex >= slides.length) {
      this.slideIndex = 0;
    }
    if (this.slideIndex < 0) {
      this.slideIndex = slides.length - 1;
    }
    slides[this.slideIndex].style.display = 'block';
  };

  /**
   * Show previous item
   * @param e
   * @private
   */
  _prevSlide = (e) => {
    e.preventDefault();
    this._showSlide(this.slideIndex - 1);
  };

  /**
   * Show next item
   * @param e
   * @private
   */
  _nextSlide = (e) => {
    e.preventDefault();
    this._showSlide(this.slideIndex + 1);
  };

  /**
   * Create the modal
   * @returns {HTMLElement}
   * @private
   */
  _renderModal = () => {
    let modal = document.createElement('DIV');
    modal.setAttribute('role', 'dialog');
    modal.setAttribute('aria-modal', 'true');
    modal.classList.add('lightbox__modal');

    let closeButton = document.createElement('BUTTON');
    closeButton.classList.add('close', 'cursor');
    closeButton.innerHTML = 'Fermer';
    closeButton.addEventListener('click', e => {
      this._closeModal(e, modal)
    });

    let modalContentWrapper = document.createElement('DIV');
    modalContentWrapper.classList.add('lightbox__modal__content');

    let galleryItems = [];
    [...this.openers].map((opener, i) => {
      let type = opener.getAttribute('data-type');
      let src = opener.getAttribute('href');

      let slideWrapper = document.createElement('div');
      slideWrapper.classList.add('lightbox__slides');

      let count = document.createElement('div');
      count.classList.add('lightbox__slides__count');
      count.innerHTML = '<span>' + (i + 1) + ' - ' + [...this.openers].length + '</span>';

      let item = null;
      switch (type) {
        case 'image' :
          let alt = opener.getAttribute('data-alt');

          item = document.createElement('IMG');
          item.setAttribute('src', src);
          item.setAttribute('alt', alt);
          break;
        case 'video' :
          let videoID = 0;
          let regex1 = /^([^\?]*)\?(.+)$/;

          if (regex1.test(src)) {
            let urlParameters = src.replace(regex1, '$2');
            src = src.replace(regex1, '$1');

            urlParameters = '{"'
              + decodeURI(urlParameters)
                .replace(/"/g, '\\"')
                .replace(/&/g, '","')
                .replace(/=/g, '":"')
              + '"}';
            urlParameters = JSON.parse(urlParameters);

            if (urlParameters.v) {
              videoID = urlParameters.v;
            }
          }

          if (videoID == 0) {
            videoID = src.split('/');
            videoID = videoID[videoID.length - 1];
          }

          src = 'https://www.youtube.com/embed/' + videoID;
          src += '?autoplay=0&mute=0&controls=1&playsinline=0&showinfo=0&rel=0&iv_load_policy=1&modestbranding=1&enablejsapi=1&widgetid=1&cc_load_policy=1&hl=fr&cc_lang_pref=fr';
          src += '&origin=' + window.location.origin;

          item = document.createElement('IFRAME');
          item.setAttribute('src', src);
          item.setAttribute('width', '1200');
          item.setAttribute('height', '675');
          item.setAttribute('frameborder', '0');
          item.setAttribute('allowfullscreen', '1');
          // item.setAttribute('allow', '1');
          break;
      }

      if (i === 0) {
        slideWrapper.style.display = 'block';
      }

      slideWrapper.appendChild(count);
      slideWrapper.appendChild(item);

      galleryItems.push(slideWrapper);
    });

    let prev = document.createElement('BUTTON');
    prev.classList.add('lightbox__modal__prev');
    prev.classList.add('icon-angleRight');
    prev.innerHTML = 'Précédent';
    prev.addEventListener('click', e => {
      this._prevSlide(e);
    });

    let next = document.createElement('BUTTON');
    next.classList.add('lightbox__modal__next');
    next.classList.add('icon-angleLeft');
    next.innerHTML = 'Suivant';
    next.addEventListener('click', e => {
      this._nextSlide(e);
    });

    modal.appendChild(closeButton);
    galleryItems.map(item => {
      modalContentWrapper.appendChild(item);
    });
    modal.appendChild(modalContentWrapper);
    modal.appendChild(prev);
    modal.appendChild(next);

    return modal;
  };
}

export default lightbox;
