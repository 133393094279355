import React from 'react';
import InputField from './TemplateElement/InputField.jsx';

export default class TextField extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    this.props.type = 'text';

    return (
      <InputField{...this.props}/>
    );
  }

}
