import React, { Component } from 'react';

export default class SliderItem extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        className={'slide slide--active hp-slider--item'}
        style={{
          'background-image': 'url(' + this.props.slide_image + ')',
        }}
      >
      </div>
    );
  }

}
