import React from 'react';
import ReactDOM from 'react-dom';

import AjaxRequester from '../../Utils/AjaxRequester';
import * as CONSTANTS from '../../Utils/constants';
import Spinner from '../Spinner.jsx';

import OrganigrammeItems from './OrganigrammeItems.jsx';

class OrganigrammeWrapper extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      status : CONSTANTS.APP_STATUS.LOADING,
      error : false,
      items : {},
    };

    this._fetchData();
  }

  _fetchData = () => {
    if (this.props.items) {
      this.setState({
        status : CONSTANTS.APP_STATUS.LOADED,
        items: this.props.items,
        error_message: '',
      });

      return;
    }

    let filters = {
      'index_id' : {
        id: ['index_id'],
        values: 'additional_data_paragraph',
        type: 'fq',
      },
    };

    let solrQueryParams = AjaxRequester.convertFiltersToParams(filters);

    solrQueryParams.rows = 1000;

    AjaxRequester.getFromSolr(
      solrQueryParams,
      (ResultsData) => {
        ResultsData.response.docs = this._parseSolrResult(ResultsData.response.docs);

        this.setState({
          status : CONSTANTS.APP_STATUS.LOADED,
          items: ResultsData.response.docs,
          error_message: '',
        });
      },
      () => {
        this.setState({
          status : CONSTANTS.APP_STATUS.ERROR,
          items: {},
          error_message: 'Une erreur s\'est produite lors du chargement du contenu.',
        })
      }
    );
  };

  _parseSolrResult = (solrResults) => {
    let resultsParsed = {};

    for (let result of solrResults) {
      let uuid = result.ss_uuid || null;

      if (!uuid) {
        continue;
      }

      resultsParsed[uuid] = {
        uuid : uuid,
        title : result.tm_X3b_fr_field_p_title
          && result.tm_X3b_fr_field_p_title[0]
            ? result.tm_X3b_fr_field_p_title[0]
            : null,
        body : result.tm_X3b_fr_field_p_body
          && result.tm_X3b_fr_field_p_body[0]
          ? result.tm_X3b_fr_field_p_body[0]
          : null,
        children : result.zm_field_p_children_paragraph_uuid || null,
        picture : result.zs_field_p_picture_uuid || null,
        type : result.ss_type || null,
      };

    }

    return resultsParsed;
  };

  render() {
    if (
      this.state.status === CONSTANTS.APP_STATUS.LOADED
      && typeof this.state.items != 'undefined'
    ) {
      return (
        <OrganigrammeItems uuids={this.props.uuids} items={this.state.items}/>
      );
    }
    else if (this.state.status === CONSTANTS.APP_STATUS.LOADING) {
      return (
        <Spinner/>
      )
    }
    else if (this.state.status === CONSTANTS.APP_STATUS.ERROR) {
      return (
        <>
          <small>
            Une erreur s'est produite durant le chargement de l'image.
          </small>
          <br/>
          <br/>
        </>
      );
    }

    return '';
  }
}

export default OrganigrammeWrapper;

const domContainers = document.querySelectorAll('.organigramme');

Object.keys(domContainers).map((index) => {
  let domContainer = domContainers[index];
  let uuids = domContainer.getAttribute('data-id') || [];
  uuids = JSON.parse(uuids);

  if (!uuids) {
    return;
  }

  ReactDOM.render(<OrganigrammeWrapper uuids={uuids}/>, domContainer);
});
