import React from 'react';

import Slick from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import SliderItem from './SliderItem.jsx';

export default class SliderItems extends React.Component {

  constructor(props) {
    super(props);

    this.internal = {
      statusPlay : 'stop',
    };

    this._prev = this._prev.bind(this);
    this._next = this._next.bind(this);
    this._play = this._play.bind(this);
    this._stop = this._stop.bind(this);
  }

  _prev = () => {
    this.slick.slickPrev();
  };

  _next = () => {
    this.slick.slickNext();
  };

  _play_n_stop = () => {
    if (this.internal.statusPlay == 'play') {
      this._stop();
    }
    else {
      this._play();
    }
  };

  _play = () => {
    this.internal.statusPlay = 'play';
    this.slick.slickPlay();
  };

  _stop = () => {
    this.internal.statusPlay = 'stop';
    this.slick.slickPause();
  };

  _renderActions = () => {
    let actions = (
      <>
        <button className={'slider__actions--play-n-stop border--before--color1 border--after--color1'} onClick={this._play_n_stop}>
          Lancer/arrêter le défilement des images
        </button>
        <button className={'slider__actions--prev border--before--color1'} onClick={this._prev}>
          Voir l'image précédente
        </button>
        <button className={'slider__actions--next border--before--color1'} onClick={this._next}>
          Voir l'image suivante
        </button>
      </>
    );

    return (
      <div className={'slider__actions'}>
        {Object.keys(this.props.items).length > 1 ? actions : null}
      </div>
    );
  };

  render() {
    let slickSettings = {
      slide: React.Fragment,
      arrows: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: false,
      autoplay: true,
      autoplaySpeed: 5000,
      fade: true,
    };

    return (
      <>
        <Slick ref={v => (this.slick = v)} {...slickSettings}>
          {
            Object.keys(this.props.items).map((index) => {
              return <SliderItem {...this.props.items[index]} />
            })
          }
        </Slick>
        {this._renderActions()}
      </>
    );
  }

}
