import React from 'react';

import AccordionItem from './AccordionItem.jsx';

export default class AccordionItems extends React.Component {

  constructor(props) {
    super(props);
  }

  handleAccordion = (e) => {
    e.preventDefault();

    let accordionItem = e.target.parentNode;
    let accordionContent = e.target.nextSibling;

    // ARIA attributes
    if (e.target.attributes.getNamedItem('aria-expanded').value === 'true') {
      e.target.attributes.getNamedItem('aria-expanded').value = 'false';
    }
    else {
      e.target.attributes.getNamedItem('aria-expanded').value = 'true';
    }

    // Class
    accordionItem.classList.toggle('open');
    accordionContent.classList.toggle('open');
  };

  render() {
    return (
      <div className={'accordion-wrapper'}>
        {
          Object.keys(this.props.items).map((index) => {
            return <AccordionItem {...this.props.items[index]} handleClick={this.handleAccordion}/>
          })
        }
      </div>
    );
  }

}
