import React from 'react';

import SearchFilter from './Filters/SearchFilter.jsx';
import ResetButton from './Filters/ResetButton.jsx';
import FormGroup from '../Form/FormGroup.jsx';
import ExportToPdfButton from '../ExportToPdf/ExportToPdfButton.jsx';
import CatalogItems from './CatalogItems.jsx';

export default class CatalogFiltersElement extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    switch (this.props.type) {
      case 'news' :
        return (
          <form>
            <FormGroup
              type={'radios'}
              name={'tag'}
              title={null}
              value={this.props.filters.TAGS && this.props.filters.TAGS.values && this.props.filters.TAGS.values.length == 1
                ? this.props.filters.TAGS.values[0]
                : null}
              required={false}
              onChange={this.props.handleNewsTag}
              options={this.props.filters.TAGS ? this.props.filters.TAGS.options || {} : {}}/>
            <ResetButton handleReset={this.props.handleReset} enableReset={this.props.enableReset}/>
          </form>
        );
      case 'search' :
        return (
          <div className="filter__bkg">
            <h2 className="filter__bloc--title">Que cherchez vous ?</h2>
            <form>
              <SearchFilter
                keyword={this.props.filters && this.props.filters.KEYWORD ? this.props.filters.KEYWORD.values[0] || null : null}
                handleSearchKeyword={this.props.handleSearchKeyword}/>
              <FormGroup
                type={'radios'}
                name={'type'}
                title={null}
                value={this.props.filters.TYPE && this.props.filters.TYPE.values && this.props.filters.TYPE.values.length == 1
                  ? this.props.filters.TYPE.values[0]
                  : null}
                required={false}
                onChange={this.props.handleSearchType}
                options={this.props.filters.TYPE ? this.props.filters.TYPE.options || {} : {}}/>
              <ResetButton handleReset={this.props.handleReset} enableReset={this.props.enableReset}/>
            </form>
          </div>
        );
      case 'faq' :
        return (
          <form>
            <FormGroup
              type={'radios'}
              name={'tag'}
              title={null}
              value={this.props.filters.TAGS && this.props.filters.TAGS.values && this.props.filters.TAGS.values.length == 1
                ? this.props.filters.TAGS.values[0]
                : null}
              required={false}
              onChange={this.props.handleFaqTag}
              options={this.props.filters.TAGS ? this.props.filters.TAGS.options || {} : {}}/>
            <ResetButton handleReset={this.props.handleReset} enableReset={this.props.enableReset}/>
          </form>
        );
      case 'faq2' :
        return (
          <form className="searchfull">
            <SearchFilter
              keyword={this.props.filters && this.props.filters.KEYWORD ? this.props.filters.KEYWORD.values[0] || null : null}
              handleSearchKeyword={this.props.handleFaqSearch}/>
          </form>
        );
      case 'menu' :
        let activeValue = this.props.filters.DATE
          && this.props.filters.DATE.values
          && this.props.filters.DATE.values[0]
          ? this.props.filters.DATE.values[0]
          : null;
        let activeValueLabel = activeValue
          && this.props.filters.DATE
          && this.props.filters.DATE.options
          && this.props.filters.DATE.options[activeValue]
          ? this.props.filters.DATE.options[activeValue].toLowerCase()
          : 'CERIA';

        return (
          <form className={'filter__block--center'}>
            <FormGroup
              type={'select'}
              name={'filter--menu-week'}
              title={'Sélectionnez la semaine'}
              value={activeValue}
              required={true}
              onChange={this.props.handleWeekFilter}
              options={this.props.filters.DATE ? this.props.filters.DATE.options || {} : {}}/>
            <ExportToPdfButton
              fileName={'menu-' + activeValueLabel.replace(/\s/ig, '-')}
              fileTitle={'Menu pour la semaine ' + activeValueLabel}
              fileContent={
                <>
                  <CatalogItems
                    items={this.props.items}
                    type={'menu-print'}/>
                  <div dangerouslySetInnerHTML={{__html: document.querySelector('.page-content').innerHTML}}></div>
                </>
                }/>
          </form>
        );
      default :
        return <React.Fragment/>;
    }
  }
}
