const CatalogSort = {

  getSort : (type) => {
    switch (type) {
      case 'search':
        return 'score desc';
      case 'agenda':
        return 'ds_end_value asc';
      case 'news':
        return 'ds_field_news_date desc';
      case 'faq':
        return 'sort_X3b_fr_title asc';
      case 'gallery':
        return 'ds_field_gallery_date desc';
      case 'menu':
        return 'ds_field_menu_date asc';
      case 'landingpage':
        return null;
      default :
        return 'sort_X3b_fr_title asc';
    }
  },

};

export default CatalogSort;
