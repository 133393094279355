import React from 'react';

export default class ViewmodeLanding extends React.Component {

  constructor(props) {
    super(props);
  }

  _renderThumbnail = (imageUri, title) => {
    let attributes = {};
    if (imageUri) {
      attributes['style'] = {
        backgroundImage: 'url("' + imageUri + '")',
      };
    }

    return (
      <div className={'blockgrid__bkg bg--color1--lighten-15'}>
        {
          imageUri ?
            <div
              style={{backgroundImage: 'url("' + imageUri + '")'}}
            >
            </div>
            : ''
            // <div className={'ghost'} aria-hidden={'true'}>
            //   {title}
            // </div>
        }
        <div className={'blockgrid__title'}>{this.props.title}</div>
      </div>
    );
  };

  render() {
    return (
      <div className={'col-xs-12 col-sm-6 col-md-6 col-lg-4'}>
        <a href={this.props.url} className={'blockgrid'}>
          {this._renderThumbnail(this.props.thumbnail_uri, this.props.title)}
        </a>
      </div>
    );
  }

}
