import React, {Component} from 'react';
import ReactDOM from 'react-dom';

import {Map, Marker, TileLayer} from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

import markerSvg from '../../../svg/marker.svg';

class Leaflet extends React.Component {

  constructor(props) {
    super(props);
  }

  _getIcon = () => {
    let svgString = markerSvg.replace('#39aea9', '#' + this.props.color);

    return new L.Icon({
      iconUrl: this._convertSvgStringToBase64(svgString),
      iconSize: [25, 31],
      iconAnchor: [12.5, 15.5],
      popupAnchor: [0, -31],
      //className: 'icon-maps',
    });
  };

  _convertSvgStringToBase64 = (svgString) => {
    return 'data:image/svg+xml;base64,' + window.btoa(svgString);
  };

  render() {
    let position = [this.props.lat, this.props.lng];

    return (
      <Map style={{width: '100%', height: '290px', marginBottom: '-12px'}}
        center={position}
        zoom={13}
        noMoveStart={false}
        dragging={false}
        scrollWheelZoom={false}
        touchZoom={false}
      >
        <TileLayer
          url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png'
        />
        <Marker position={position} icon={this._getIcon()}/>
      </Map>
    );
  }

}

export default Leaflet;

const domContainer = document.querySelector('.content__header--map');
if (domContainer) {
  let lat = domContainer.getAttribute('data-lat');
  let lng = domContainer.getAttribute('data-lng');
  let color = domContainer.getAttribute('data-color');

  ReactDOM.render(<Leaflet lat={lat} lng={lng} color={color}/>, domContainer);
}
