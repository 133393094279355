import React from 'react';

export default class ResetButton extends React.Component {

  handleOnClick = (e) => {
    e.preventDefault();

    this.props.handleReset();
  };

  render() {
    this.props.attributes = this.props.attributes || {};

    if (!this.props.enableReset) {
      this.props.attributes['disabled'] = 'disabled';
    }

    return (
      <div className={'form-group'}>
        <button className="btn btn--outline" type="button" onClick={this.handleOnClick} {...this.props.attributes}>
          Réinitialiser
        </button>
      </div>
    );
  }

}
