const mobileMenu = {
  init : function (elementHtmlTag, selectorElements = '.menu--mobile--button') {
    let clickElements = elementHtmlTag.querySelectorAll(selectorElements);
    Object.keys(clickElements).map((index) => {
      let clickElement = clickElements[index];
      let mobileMenu = new _mobileMenuPrivate(elementHtmlTag, clickElement);
      mobileMenu.listen();
    });
  },
};

class _mobileMenuPrivate {
  constructor(menuElementHtmlTag, openButton) {
    this.menuElementHtmlTag = menuElementHtmlTag;
    this.openButton = openButton;
    this.isOpen = false;
    this.classOpen = 'menu-mobile--open';
    this.classClose = 'menu-mobile--close';
  }

  listen() {
    if (!this.exists(this.menuElementHtmlTag)) {
      return;
    }

    this.openButton.addEventListener('click', (e) => {
      e.preventDefault();
      this.toggle();
    });
  }

  exists(htmlElement) {
    return htmlElement
      && typeof htmlElement != 'undefined';
  }

  toggle() {
    /**
     * Check if valid variable
     **/
    if (!this.exists(this.menuElementHtmlTag)) {
      return;
    }

    this.openAnimation();

    /**
     * Open/Close focused dropdown
     **/
    if (!this.isOpen) {
      document.addEventListener('mousedown', this.outsideClickListener.bind(this));
    }
    else {
      document.removeEventListener('mousedown', this.outsideClickListener);
    }

    this.isOpen = !this.isOpen;
  }

  outsideClickListener(event) {
    if (
      this.menuElementHtmlTag
      && !this.menuElementHtmlTag.contains(event.target)
      && this.isOpen === true
    ) {
      this.toggle();
    }
  }

  openAnimation() {
    this.menuElementHtmlTag.classList.remove(this.isOpen ? this.classOpen : this.classClose);
    this.menuElementHtmlTag.classList.add(this.isOpen ? this.classClose : this.classOpen);

    this.openButton.setAttribute('aria-expanded', this.isOpen ? 'false' : 'true');

    // this.menuElementHtmlTag.querySelectorAll(':scope > ul') // => IE... :(
    let menuChildren = this.menuElementHtmlTag.childNodes;
    Object.keys(menuChildren).map((index) => {
      let expandedElement = menuChildren[index];
      if (expandedElement.tagName == 'ul') {
        expandedElement.setAttribute('aria-hidden', this.isOpen ? 'true' : 'false');
      }
    });

    /**
     * Avoid scrolling when menu is opened
     **/
    if (this.isOpen) {
      document.querySelector('body').classList.remove(this.classOpen);
    }
    else {
      document.querySelector('body').classList.add(this.classOpen);
    }
  }
}

export default mobileMenu;
