const mainMenuSticky = {
  init : (menuElementHtmlTag, headerWithBannerHtmlTag) => {
    let stickyMenu = new _mainMenuStickyPrivate(menuElementHtmlTag, headerWithBannerHtmlTag);
    stickyMenu.listen();
  }
};


class _mainMenuStickyPrivate {

  constructor(menuElementHtmlTag, headerWithBannerHtmlTag = null) {
    this.menuElementHtmlTag = menuElementHtmlTag;

    this.lastScrollPosition = window.pageYOffset || window.scrollY;
    let headerOffset = headerWithBannerHtmlTag
      ? headerWithBannerHtmlTag.offsetHeight
      : this.menuElementHtmlTag.offsetHeight;
    this.maxPosition1 = headerOffset;
    this.maxPosition2 = headerOffset - this.menuElementHtmlTag.offsetHeight;
  }

  listen() {
    if (!this._exists(this.menuElementHtmlTag)) {
      return;
    }

    window.addEventListener('scroll', (e) => {
      this._detectNSetScrollClass();
    });
  }

  _exists(htmlElement) {
    return htmlElement
      && typeof htmlElement != 'undefined';
  }

  _detectNSetScrollClass() {
    /**
     * Check if valid variable
     **/
    if (!this._exists(this.menuElementHtmlTag)) {
      return;
    }

    let currentScrollPosition = Math.round(window.pageYOffset || window.scrollY);

    if (this.lastScrollPosition == currentScrollPosition) {
      return;
    }

    /*
     * Scroll to top + after header
     */
    if (
      this.lastScrollPosition > currentScrollPosition
      && currentScrollPosition > this.maxPosition1
    ) {
      this._setScrollClass(0);
    }
    /*
     * Scroll to bottom + after header
     * Scroll to top + after elt
     */
    else if (
      this.lastScrollPosition < currentScrollPosition
      && currentScrollPosition > this.maxPosition1
    ) {
      this._setScrollClass(1);
    }
    else if (
      this.lastScrollPosition > currentScrollPosition
      && currentScrollPosition > this.maxPosition2
    ) {
      this._setScrollClass(2);
    }
    else {
      this._setScrollClass(3);
    }

    this.lastScrollPosition = currentScrollPosition;
  }

  _setScrollClass(scrollCase) {
    let className = 'on-scroll';
    let classNameWhenHide = 'on-scroll--hidden';
    let classNameWhenShow = 'on-scroll--shown';

    switch (scrollCase) {
      case 0 :
        if (!this.menuElementHtmlTag.classList.contains(classNameWhenShow)) {
          this.menuElementHtmlTag.classList.remove(classNameWhenHide);
          this.menuElementHtmlTag.classList.add(classNameWhenShow);
          this.menuElementHtmlTag.classList.add(className);
        }
        break;
      case 1 :
        if (!this.menuElementHtmlTag.classList.contains(classNameWhenShow)) {
          this.menuElementHtmlTag.classList.remove(classNameWhenHide);
          this.menuElementHtmlTag.classList.add(classNameWhenShow);
          this.menuElementHtmlTag.classList.add(className);
        }
        break;
      case 2 :
        if (!this.menuElementHtmlTag.classList.contains(classNameWhenHide)) {
          this.menuElementHtmlTag.classList.remove(classNameWhenShow);
          this.menuElementHtmlTag.classList.add(classNameWhenHide);
          this.menuElementHtmlTag.classList.add(className);
        }
        break;
      case 3 :
        if (this.menuElementHtmlTag.classList.contains(className)) {
          this.menuElementHtmlTag.classList.remove(classNameWhenHide);
          this.menuElementHtmlTag.classList.remove(classNameWhenShow);
          this.menuElementHtmlTag.classList.remove(className);
        }
        break;
    }
  }

}

export default mainMenuSticky;
