import React from 'react';

import AccordionItems from '../Accordion/AccordionItems.jsx';
import OrganigrammeItem from './OrganigrammeItem.jsx';

export default class OrganigrammeItems extends React.Component {

  constructor(props) {
    super(props);
  }

  _getItem = (organigrammeItem) => {
    if (organigrammeItem.type == 'organigramme') {
      let accordionItems = {
        0 : {
          title : organigrammeItem.title || null,
          body : (
            <>
              {
                organigrammeItem.children
                  ? <OrganigrammeItems uuids={organigrammeItem.children} items={this.props.items}/>
                  : <OrganigrammeItem{...organigrammeItem}/>
              }
            </>
          ),
        },
      };

      return (
        <AccordionItems items={accordionItems}/>
      );
    }
    else {
      return (
        <>
          <OrganigrammeItem{...organigrammeItem}/>
          {
            organigrammeItem.children
              ? <OrganigrammeItems uuids={organigrammeItem.children} items={this.props.items}/>
              : ''
          }
        </>
      );
    }
  };

  render() {
    return (
      <>
        {
          Object.keys(this.props.uuids).map((index) => {
            let uuid = this.props.uuids[index];

            if (!this.props.items[uuid]) {
              return;
            }

            return this._getItem(this.props.items[uuid]);
          })
        }
      </>
    );
  }

}
