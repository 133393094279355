import React from 'react';
import EmbeddedMedia from "../Media/EmbeddedMedia.jsx";

export default class AccordionItem extends React.Component {

  constructor(props) {
    super(props);
  }

  handleClick = (e) => {
    // Do not need for input
    e.preventDefault();

    this.props.handleClick(e);
  };

  render() {
    this.props.mediaUuid = this.props.mediaUuid || [];

    return (
      <div className={'accordion__item'}>
        <a className={'accordion__header'} href={'#'} aria-expanded={'false'} onClick={this.handleClick}>
          <h4 className={'block-content__title'} id={this.props.id}>
            {this.props.title}
          </h4>
        </a>
        <div className={'accordion__body'}>
          {
            typeof this.props.body === 'string'
              ? <div className={'accordion__content'} dangerouslySetInnerHTML={{__html: this.props.body}}></div>
              : <div className={'accordion__content'}>{this.props.body}</div>
          }
          {
            Object.keys(this.props.mediaUuid).map((index) => {
              return (
                <EmbeddedMedia uuid={this.props.mediaUuid[index]} alt={''} type={'media--' + (this.props.mediaType[index] || 'image') } imageStyle={'embed_wysiwyg_image'}/>
              );
            })
          }
        </div>
      </div>
    );
  }

}
