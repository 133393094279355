import React from "react";

const CatalogPager = ({ currentPage, itemsPerPage, length, onPageChanged }) => {
  const pagesCount = Math.ceil(length / itemsPerPage );
  const pages = [];


  for (let i = 1; i <= pagesCount; i++) {
    pages.push(i);
  }

  if (pagesCount > 1 )
  {
    return (
      <div className={"pager"}>
        <button className={"pager--link bg--color1--lighten-30 " + (currentPage === 0 && "disabled")} onClick={() => onPageChanged(currentPage - 1)}>
          Page précédente<i className="icon" aria-hidden="true">«</i>
        </button>
        <button className={"pager--link bg--color1--lighten-30 " + (currentPage === (pagesCount - 1) && "disabled")} onClick={() => onPageChanged(currentPage + 1)}>
          Page suivante<i className="icon" aria-hidden="true">»</i>
        </button>
      </div>

    ) } else {
      return (
        <>
        </>
      )
  }

};



export default CatalogPager;
