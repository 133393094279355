import {addDays} from 'date-fns';
import {getFirstDayOfCurrentWeek, getLastDayOfCurrentWeek} from '../../../Utils/DateOperations.jsx';

const CatalogPrepareItems = {

  getParsedItems : (type, items, faqUrl) => {
    if (
      !items
      || items.length <= 0
    ) {
      return [];
    }

    switch (type) {
      case 'search' : return CatalogPrepareItems._getParsedItemsForSearch(items, faqUrl);
      case 'menu' : return CatalogPrepareItems._getParsedItemsForMenu(items);
      default : return items;
    }
  },

  _getParsedItemsForSearch : (items, faqUrl) => {
    if (faqUrl) {
      Object.keys(items).map((index) => {
        if (items[index].type == 'faq') {
          items[index].url = [faqUrl];
          //items[index].url = [faqUrl + '?keword=toto#n-' + items[index].nid];
        }
      });
    }

    return items;
  },

  _getParsedItemsForMenu : (items) => {
    let week = {};
    let startDate = null;
    let endDate = null;

    Object.keys(items).map(index => {
      let item = items[index];
      let date = new Date(item.menu_date);
      let weekNo = date.getDay() || 7; // Sunday = 0

      if (!startDate && !endDate) {
        startDate = getFirstDayOfCurrentWeek(date);
        endDate = getLastDayOfCurrentWeek(date);
      }

      if (
        startDate.getTime() <= date.getTime()
        && date.getTime() <= endDate.getTime()
      ) {
        week[weekNo] = item;
      }
    });

    for (let i = 1; i <= 5; i++) {
      if (!week[i]) {
        let dayOfWeek = i == 1
          ? startDate
          : addDays(startDate, (i - 1));

        week[i] = {
          body: 'Aucun menu disponible pour le moment',
          menu_date: dayOfWeek.toISOString(),
          menu_type_display: 2,
        };
      }
    }

    return week;
  },

};

export default CatalogPrepareItems;
