import React from 'react';

import ViewmodeEvent from '../Viewmode/ViewmodeEvent.jsx';
import ViewmodeNews from '../Viewmode/ViewmodeNews.jsx';
import ViewmodeMenu from '../Viewmode/ViewmodeMenu.jsx';
import ViewmodeMenuPrint from '../Viewmode/ViewmodeMenuPrint.jsx';
import ViewmodeLanding from '../Viewmode/ViewmodeLanding.jsx';
import ViewmodeSearch from '../Viewmode/ViewmodeSearch.jsx';
import ViewmodeGallery from '../Viewmode/ViewmodeGallery.jsx';

export default class CatalogItem extends React.Component {

  render() {
    switch (this.props.type) {
      case 'agenda':
        return (
          <ViewmodeEvent {...this.props.item}/>
        );
      case 'news':
        return (
          <ViewmodeNews {...this.props.item}/>
        );
      case 'search':
        return (
          <ViewmodeSearch {...this.props.item}/>
        );
      case 'gallery':
        return (
          <ViewmodeGallery {...this.props.item}/>
        );
      case 'menu':
        return (
          <ViewmodeMenu {...this.props.item}/>
        );
      case 'menu-print':
        return (
          <ViewmodeMenuPrint {...this.props.item}/>
        );
      case 'landingpage':
        return (
          <ViewmodeLanding {...this.props.item}/>
        );
      default:
        return <React.Fragment/>
    }
  };
}
