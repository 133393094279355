import React from 'react';
import ReactDOM from 'react-dom';

import CatalogItems from './CatalogItems.jsx';
import CatalogPager from './CatalogPager.jsx';
import CatalogFiltersElement from './CatalogFiltersElement.jsx';
import CatalogResultsNumber from './CatalogResultsNumber.jsx';
import CatalogFilters from './Utils/CatalogFilters';
import CatalogRows from './Utils/CatalogRows';
import CatalogSort from './Utils/CatalogSort';
import CatalogPrepareItems from './Utils/CatalogPrepareItems';
import Spinner from '../Spinner.jsx';

import AjaxRequester from '../../Utils/AjaxRequester';
import * as CONSTANTS from '../../Utils/constants';
import * as CATALOG_CONSTANT from './CatalogConstant.jsx';

class CatalogWrapper extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      filters : {},
      usingExposedFilter : false,
      page : 0,
      data : [],
      parsedData : [],
      dataCount : 0,
      originalData : [],
      status : CONSTANTS.APP_STATUS.LOADING,
    };

    this.props.filters = this.props.filters
      ? JSON.parse(this.props.filters)
      : {};

    this._defaultFilters();
  }

  componentDidMount() {
    this.setState({
      status: CONSTANTS.APP_STATUS.LOADING
    });

    this.fetchData();
  }

  _defaultFilters = () => {
    this.state.filters = CatalogFilters.getFilters(this.props.prefix, this.props.type, this.props.filters, this.props.faq);
  };

  /**
   * Handle results pagination
   * @param page
   */
  handlePager = (page) => {
    this.setState({
      page: page,
      status: CONSTANTS.APP_STATUS.FILTERING
    }, () => {
      this.fetchData();
    })
  };


  fetchData = () => {
    let solrQueryParams = AjaxRequester.convertFiltersToParams(this.state.filters);
    this.state.usingExposedFilter = solrQueryParams.q != '*';

    let sort = CatalogSort.getSort(this.props.type);
    if (sort) {
      solrQueryParams.sort = sort;
    }


    let appNumberByPage = CatalogRows.getRowsNumber(this.props.type);
    if (appNumberByPage) {
      solrQueryParams.rows = appNumberByPage;
      solrQueryParams.start = appNumberByPage * this.state.page;
    }
// Gestion du hightlight du résultat après une recherche
    if (this.state.filters.KEYWORD) {
      solrQueryParams['hl.fl'] = this.state.filters.KEYWORD.id.join(' ');
      solrQueryParams['hl'] = 'on';
      solrQueryParams['hl.simple.pre'] = '<span class="highlightSearch bg--color1">';
      solrQueryParams['hl.simple.post'] = '</span>';
    }

    AjaxRequester.getFromSolr(
      solrQueryParams,
      (ResultsData) => {
        ResultsData.response.docs = AjaxRequester.convertSolrResultsToViewExport(ResultsData, this.props.type);
        if (this.state.originalData.length === 0) {
          this.state.originalData = ResultsData.response.docs;
        }

        this.setState({
          status : CONSTANTS.APP_STATUS.LOADED,
          page : this.state.page,
          data : ResultsData.response.docs,
          parsedData : CatalogPrepareItems.getParsedItems(this.props.type, ResultsData.response.docs, this.props.faq),
          dataCount : ResultsData.response.numFound,
          filters : CatalogFilters.prepareFilters(this.props.type, this.state.originalData),
        });
      },
      () => {
        this.setState({
          data: [],
          parsedData: [],
          status: CONSTANTS.APP_STATUS.ERROR
        });
      }
     );
  };

  handleFilter = (value, id, solrFieldNames, filterType = 'q') => {
    this.state.filters[id] = {
      id: solrFieldNames,
      values: value,
      type: filterType,
    };

    this.setState(
      {
        status: CONSTANTS.APP_STATUS.FILTERING,
      },
      () => {
        this.state.usingExposedFilter = true;
        this.fetchData();
      }
    );
  };

  handleSearchKeyword = (value) => {
    this.handleFilter(value, 'KEYWORD', CATALOG_CONSTANT.APP_FILTER.SEARCH.KEYWORD.solrFieldNames);

    if (this.state.filters.TYPE) {
      delete this.state.filters.TYPE;
    }

    this.props.filters.KEYWORD = [value];
  };

  handleSearchType = (e) => {
    this.handleFilter(e.target.value, 'TYPE', CATALOG_CONSTANT.APP_FILTER.SEARCH.TYPE.solrFieldNames);
  };

  handleNewsTag = (e) => {
    this.handleFilter(e.target.value, 'TAGS', CATALOG_CONSTANT.APP_FILTER.NEWS.TAGS.solrFieldNames);
  };

  handleFaqTag = (e) => {
    this.handleFilter(e.target.value, 'TAGS', CATALOG_CONSTANT.APP_FILTER.FAQ.TAGS.solrFieldNames);
  };

  handleFaqSearch = (value) => {
    this.handleFilter(value, 'KEYWORD', CATALOG_CONSTANT.APP_FILTER.FAQ.KEYWORD.solrFieldNames);
  };

  handleMenuWeek = (e) => {
    this.handleFilter(e.target.value, 'DATE', CATALOG_CONSTANT.APP_FILTER.MENU.DATE.solrFieldNames);
  };

  handleReset = () => {
    this._defaultFilters();

    this.setState(
      {
        status: CONSTANTS.APP_STATUS.LOADING,
      },
      () => {
        this.fetchData();
      }
    );
  };

  render() {
    if (this.state.status === CONSTANTS.APP_STATUS.LOADED) {
      switch (this.props.type) {
        case 'agenda' :
          return (
            <div className={'catalog-wrapper'}>
              <section className={'agenda--listing'}>
                <CatalogItems
                  items={this.state.parsedData}
                  type={this.props.type}/>
              </section>
            </div>
          );
        case 'news' :
          return (
            <div className={'row'}>
              <aside className={'col-xs-12 col-md-4 col-lg-3 filter__block filter__block--right'}>
                <CatalogFiltersElement
                  filters={this.state.filters}
                  type={this.props.type}
                  handleNewsTag={this.handleNewsTag}
                  handleReset={this.handleReset}
                  enableReset={this.state.usingExposedFilter}/>
              </aside>
              <div className={'col-xs-12 col-md-8 col-lg-9'}>
                <section className={'news--listing'}>
                  <CatalogItems
                    items={this.state.parsedData}
                    type={this.props.type}/>
                </section>
              </div>
            </div>
          );
        case 'search' :
          return (
            <>
              <aside className={'col-xs-12 col-md-4 col-lg-3 filter__bloc'}>
                <CatalogFiltersElement
                  filters={this.state.filters}
                  type={this.props.type}
                  handleSearchKeyword={this.handleSearchKeyword}
                  handleSearchType={this.handleSearchType}
                  handleReset={this.handleReset}
                  enableReset={this.state.usingExposedFilter}/>
              </aside>
              <div className={'col-xs-12 col-md-8 col-lg-9'}>
                <CatalogResultsNumber
                  keyword={
                    this.state.filters
                    && this.state.filters.KEYWORD
                    && this.state.filters.KEYWORD.values
                    ? this.state.filters.KEYWORD.values[0] || null
                    : null
                  }
                  resultsNumber={this.state.dataCount}/>
                <CatalogItems
                  items={this.state.parsedData}
                  type={this.props.type}
                  faq={this.props.faq}/>
                <CatalogPager
                  currentPage={this.state.page}
                  itemsPerPage={10}
                  length={this.state.dataCount}
                  onPageChanged={this.handlePager}/>
              </div>
            </>
          );
        case 'faq' :
          return (
            <>
              <aside className={'col-xs-12 col-md-4 col-lg-3 filter__block'}>
                <CatalogFiltersElement
                  filters={this.state.filters}
                  type={this.props.type}
                  handleFaqTag={this.handleFaqTag}
                  handleReset={this.handleReset}
                  enableReset={this.state.usingExposedFilter}/>
              </aside>
              <div className={'col-xs-12 col-md-8 col-lg-9'}>
                <CatalogFiltersElement
                  type={'faq2'}
                  filters={this.state.filters}
                  handleFaqSearch={this.handleFaqSearch}/>
                <CatalogItems
                  items={this.state.parsedData}
                  type={this.props.type}/>
              </div>
            </>
          );
        case 'gallery' :
          return (
            <div className={'col-xs-12'}>
              <section className={'row'}>
                <CatalogItems
                  items={this.state.parsedData}
                  type={this.props.type}/>
              </section>
            </div>
          );
        case 'menu' :
          let activeValue = this.state.filters.DATE
          && this.state.filters.DATE.values
          && this.state.filters.DATE.values[0]
            ? this.state.filters.DATE.values[0]
            : null;
          let activeValueLabel = activeValue
          && this.state.filters.DATE
          && this.state.filters.DATE.options
          && this.state.filters.DATE.options[activeValue]
            ? this.state.filters.DATE.options[activeValue].toLowerCase()
            : '';

          return (
            <div className={'col-xs-12'}>
              <CatalogFiltersElement
                filters={this.state.filters}
                type={this.props.type}
                items={this.state.parsedData}
                handleWeekFilter={this.handleMenuWeek}/>
              <section className={'row'}>
                <h2 className={'title__center col-xs-12'}>Menu de la semaine {activeValueLabel}</h2>
                <CatalogItems
                  items={this.state.parsedData}
                  type={this.props.type}/>
              </section>
            </div>
          );
        case 'landingpage' :
          return (
            <CatalogItems
              items={this.state.parsedData}
              type={this.props.type}/>
          );
      }
    }
    else if (
      this.state.status === CONSTANTS.APP_STATUS.LOADING
      || this.state.status === CONSTANTS.APP_STATUS.FILTERING
    ) {
      return (
        <div className={'col-xs-12'}>
          <Spinner/>
        </div>
      );
    }
    else if (this.state.status === CONSTANTS.APP_STATUS.ERROR) {
      return (
        <div className={'col-xs-12'}>
          <div className={'alert alert-danger'}>
            <strong>Erreur ! </strong>
            Une erreur s'est produite durant le chargement des élèments.
          </div>
        </div>
      );
    }
  }
}

export default CatalogWrapper;

const domContainer = document.querySelector('.catalog');

if (domContainer) {
  const prefix = domContainer.getAttribute('data-prefix');
  const type = domContainer.getAttribute('data-type');
  const filters = domContainer.getAttribute('data-filters');
  const faq = domContainer.getAttribute('data-faq');

  ReactDOM.render(<CatalogWrapper prefix={prefix} type={type} filters={filters} faq={faq}/>, domContainer);
}
