import {addDays, format} from 'date-fns';
import {fr} from 'date-fns/locale';
import { getFirstDayOfCurrentWeek, getLastDayOfCurrentWeek } from '../../../Utils/DateOperations.jsx';

import * as CATALOG_CONSTANT from '../CatalogConstant.jsx';

const CatalogFilters = {

  filters : {},

  drupalContentType : {
    basicpage : {
      label :  'Contenu',
      condition : '(basicpage OR landingpage)',
    },
    landingpage : {
      label :  'Contenu',
      condition : '(basicpage OR landingpage)',
    },
    formation : {
      label :  'Formation',
      condition : 'formation',
    },
    faq : {
      label :  'FAQ',
      condition : 'faq',
    },
    evenement : {
      label :  'Evènement',
      condition : 'evenement',
    },
    news : {
      label :  'Actualité',
      condition : 'news',
    },
    file : {
      label :  'Fichier',
      condition : 'file',
    },
    gallerie : {
      label :  'Galerie',
      condition : 'gallerie',
    },
    menu : {
      label :  'Menu restaurant',
      condition : 'menu',
    },
    accesspage : {
      label :  'Accès',
      condition : 'accesspage',
    },
    homepage : {
      label :  'Page d\'accueil',
      condition : 'homepage',
    },
  },

  getFilters : (site, type, defaultFilters, isAvailableFaqPage) => {
    CatalogFilters.filters = [];
    CatalogFilters._setFilterSite(site);
    CatalogFilters._setFiltersPerType(type, defaultFilters);
    CatalogFilters._setFilterForSearchCatalog(type, defaultFilters, isAvailableFaqPage);
    CatalogFilters._setFiltersFromUrl(type);

    return CatalogFilters.filters;
  },

  _setFilterSite : (site) => {
    CatalogFilters.filters['ss_field_site_shortname'] = {
      id: ['ss_field_site_shortname'],
      values: site,
      type: 'fq',
    };
  },

  _setFiltersPerType : (type, defaultFilters) => {
    let appFiltersConstant = CatalogFilters._getFilterConfigPerType(type);

    for (let filterName in appFiltersConstant) {
      if (
        appFiltersConstant[filterName].values
        || defaultFilters[filterName]
      ) {
        CatalogFilters._convertConstantToFilter(filterName, appFiltersConstant[filterName], defaultFilters[filterName] || null);
      }
    }
  },

  _setFilterForSearchCatalog : (type, defaultFilters, isAvailableFaqPage) => {
    if (
      type != 'search'
      || (
        defaultFilters.TYPE
        && defaultFilters.TYPE.values
      )
    ) {
      return;
    }

    if (
      isAvailableFaqPage
      && CATALOG_CONSTANT.APP_FILTER.SEARCH
      && CATALOG_CONSTANT.APP_FILTER.SEARCH.TYPE
      && CATALOG_CONSTANT.APP_FILTER.SEARCH.TYPE.values
      && CatalogFilters.filters.TYPE
      && CatalogFilters.filters.TYPE.values
    ) {
      CatalogFilters.filters.TYPE.values.push('faq');
    }
  },

  _setFiltersFromUrl : (type) => {
    let urlParameters = CatalogFilters._getUrlParameters();
    let appFiltersConstant = CatalogFilters._getFilterConfigPerType(type);

    for (let urlParameterName of Object.keys(urlParameters)) {
      let urlParameterValue = urlParameters[urlParameterName];
      urlParameterName = urlParameterName.toUpperCase();

      if (appFiltersConstant[urlParameterName]) {
        if (Array.isArray(urlParameterValue)) {
          urlParameterValue.sort();
        }
        else {
          urlParameterValue.trim();
        }

        if (urlParameterValue.length > 0) {
          appFiltersConstant.exposed = true;
          CatalogFilters._convertConstantToFilter(urlParameterName, appFiltersConstant[urlParameterName], urlParameterValue);
        }
      }
    }
  },

  _getFilterConfigPerType : (type) => {
    switch (type) {
      case 'agenda':
        return CATALOG_CONSTANT.APP_FILTER.EVENT;
        break;
      case 'landingpage':
        return CATALOG_CONSTANT.APP_FILTER.LANDING;
        break;
      default :
        return CATALOG_CONSTANT.APP_FILTER[type.toUpperCase()] || {};
        break;
    }
  },

  _convertConstantToFilter : (filterName, constantFilter, values) => {
    CatalogFilters.filters[filterName] = {
      id : constantFilter.solrFieldNames,
      values : values || constantFilter.values,
      type : constantFilter.forceType || (constantFilter.exposed ? 'q' : 'fq'),
    };
  },

  _getUrlParameters : () => {
    let urlParameters = window.location.search.replace(/^\?/, '');

    if (urlParameters) {
      try {
        urlParameters = '{"'
          + decodeURI(urlParameters)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"')
            .replace(/\+/g, ' ')
          + '"}';

        urlParameters = JSON.parse(urlParameters);
      }
      catch (e) {
        urlParameters = {};
      }
    }
    else {
      urlParameters = {};
    }

    return urlParameters;
  },

  prepareFilters : (type, fullResults) => {
    let appFiltersConstant = CatalogFilters._getFilterConfigPerType(type);

    Object.keys(appFiltersConstant).map((filterName) => {
      if (
        !appFiltersConstant[filterName]
        || !appFiltersConstant[filterName].exposed
      ) {
        return;
      }

      let options = {};
      let optionsCount = {};

      switch (filterName) {
        case 'DATE' :
          let date = new Date();
          let startDate = getFirstDayOfCurrentWeek(date);
          let endDate = getLastDayOfCurrentWeek(date);

          for (let i = 0; i <= 5; i++) {
            if (i != 0) {
              startDate = addDays(startDate, 7);
              endDate = addDays(endDate, 7);
            }

            let optionValue = '[' + startDate.toISOString() + ' TO ' + endDate.toISOString() + ']';
            let optionLabel = 'Du ' + format(startDate, 'd MMMM yyyy', {locale: fr});
            optionLabel += ' au ' + format(endDate, 'd MMMM yyyy', {locale: fr});

            options[optionValue] = optionLabel;
          }
          break;
        case 'TAGS' :
          Object.keys(fullResults).map((itemIndex) => {
            let item = fullResults[itemIndex];

            Object.keys(item.tags || []).map((tagIndex) => {
              let tagName = item.tags[tagIndex];
              optionsCount[tagName] = optionsCount[tagName] || 0;
              optionsCount[tagName]++;
              options[tagName] = tagName + ' (' + optionsCount[tagName] + ')';
            });
          });
          break;
        case 'TYPE' :
          Object.keys(fullResults).map(itemIndex => {
            let item = fullResults[itemIndex];
            let itemProperties = CatalogFilters.drupalContentType[item.type];

            optionsCount[itemProperties.condition] = optionsCount[itemProperties.condition] || 0;
            optionsCount[itemProperties.condition]++;
            options[itemProperties.condition] = itemProperties.label + ' (' + optionsCount[itemProperties.condition] + ')';
          });
          break;
        case 'END' :
        case 'KEYWORD' :
        case 'INDEX' :
        case 'URL' :
          break;
      }

      if (
        typeof options != 'undefined'
        && Object.keys(options).length > 0
      ) {
        // Set options (filter : radio - checkbox - select)
        if (!CatalogFilters.filters[filterName]) {
          CatalogFilters._convertConstantToFilter(filterName, appFiltersConstant[filterName]);
        }
        CatalogFilters.filters[filterName].options = options;

        // Select the first option if no value
        if (
          appFiltersConstant[filterName].neverEmpty
          && (
            !CatalogFilters.filters[filterName].values
            || !CatalogFilters.filters[filterName].values[0]
            || !options[CatalogFilters.filters[filterName].values[0]]
          )
        ) {
          CatalogFilters.filters[filterName].values = [Object.keys(options).slice(0,1)];
        }
      }
    });

    return CatalogFilters.filters;
  },

};

export default CatalogFilters;
