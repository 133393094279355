import React from 'react';

import InputField from './TemplateElement/InputField.jsx';

export default class DateField extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    this.props.type = 'date';

    this.props.attributes = this.props.attributes || {};
    this.props.attributes.format = this.props.format || null;
    this.props.attributes.max = this.props.max || null;
    this.props.attributes.min = this.props.min || null;
    this.props.attributes.step = this.props.step || null;

    return (
      <InputField{...this.props}/>
    );
  }

}
