import React from 'react';

export default class CatalogResultsNumber extends React.Component {

  constructor(props) {
    super(props);

    if (!this.props.label && this.props.resultsNumber) {
      this.props.label = this.props.resultsNumber + ' résultat(s)';

      if (this.props.keyword ) {
        this.props.label += ' pour "<strong>' + this.props.keyword + '</strong>"';
      }
    }
  }

  render() {
    if (!this.props.label) {
      return '';
    }

    return (
      <h3 className="numberresult" dangerouslySetInnerHTML={{ __html: this.props.label }}>
      </h3>
    );
  }
}
