import React from 'react';
import FormGroup from '../../Form/FormGroup.jsx';
import HiddenField from '../../Form/FormElement/HiddenField.jsx';

export default class TotalFromOptionsWithPrice extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      total: '',
    };
  }

  handleChange = (e) => {
    this.props.onChange({
      preventDefault: () => {},
      target: {
        getAttribute: (att) => {
          switch (att) {
            case 'name' : return this.props.name;
            default : return null;
          }
        },
        value: this.state.total,
      }
    });
  };

  _setTotal = () => {
    let total = parseInt(this.props.initial_price);
    let discount = 0;

    Object.keys(this.props.prices).map((index) => {
      let subPrice = this.props.prices[index].value;

      if (!subPrice) {
        return;
      }

      let subType = this.props.prices[index].type;

      if (subType == 'options_with_discount') {
        discount += subPrice;
      }
      else {
        total += subPrice;
      }
    });

    if (total > this.props.max_price) {
      total = this.props.max_price;
    }

    total -= discount;

    if (total < this.props.min_price) {
      total = this.props.min_price;
    }

    this.state.total = this._convertCentsToEuros(total);
  };

  _convertCentsToEuros = (price) => {
    let quotient = Math.floor(price / 100);
    let remainder = price % 100;
    remainder = remainder.toString().replace(/^(\d)$/, '$&0');

    return quotient + ',' + remainder + ' €';
  };

  render() {
    this._setTotal();
    this.handleChange(null);

    this.props.wrapperAttributes = this.props.wrapperAttributes || {};
    this.props.wrapperAttributes.className = this.props.wrapperAttributes.className || '';
    this.props.wrapperAttributes.className += ' form-total';
    this.props.wrapperAttributes.className.trim();

    this.props.content = (
      <div className={'total'}>
        <span className={'font--color1'}>{this.state.total}</span>
        <sup>*</sup>
      </div>
    );
    let {onChange,...other} = this.props;

    return (
      <>
        <FormGroup {...other}/>
        <HiddenField
           name={this.props.name}
           id={this.props.name}
           value={this.state.total}
           onChange={this.handleChange}/>
      </>
    );
  }

}
