import React from 'react';
import CheckboxesRadiosField from './TemplateElement/CheckboxesRadiosField.jsx';

export default class RadiosField extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    this.props.type = 'radio';

    return (
      <CheckboxesRadiosField{...this.props}/>
    );
  }

}
