import React from 'react';

import TextField from './FormElement/TextField.jsx';
import EmailField from './FormElement/EmailField.jsx';
import TelephoneField from './FormElement/TelephoneField.jsx';
import DateField from './FormElement/DateField.jsx';
import TextareaField from './FormElement/TextareaField.jsx';
import SelectField from './FormElement/SelectField.jsx';
import CheckboxesField from './FormElement/CheckboxesField.jsx';
import RadiosField from './FormElement/RadiosField.jsx';
import EntityReferenceField from './FormElement/EntityReferenceField.jsx';
import FileField from './FormElement/FileField.jsx';
import FieldsetElement from './FormElement/FieldsetElement.jsx';
import SubmitField from './FormElement/SubmitField.jsx';
import SubmitButton from './FormElement/SubmitButton.jsx';

export default class FormGroup extends React.Component {

  constructor(props) {
    super(props);
  }

  _getTitle = () => {
    if (
      !this.props.title
      || this.props.type == 'fieldset'
      || this.props.type == 'SubmitButton'
      || this.props.type == 'button'
      || this.props.type == 'submit'
    ) {
      return '';
    }

    let attributes = {};

    if (this.props.title_hidden) {
      attributes.className = 'visually-hidden';
    }

    return (
      <label htmlFor={this.props.name}{...attributes}>
        {this.props.title}&nbsp;{this.props.required ? <sup>*</sup> : ''}
      </label>
    );
  };

  _getContent = () => {
    let {wrapperAttributes,type,...other} = this.props;

    switch (this.props.type) {
      case 'textfield' :
        return <TextField{...other}/>;
      case 'email' :
        return <EmailField{...other}/>;
      case 'tel' :
        return <TelephoneField{...other}/>;
      case 'date' :
        return <DateField{...other}/>;
      case 'textarea' :
        return <TextareaField{...other}/>;
      case 'select' :
        return <SelectField{...other}/>;
      case 'checkboxes' :
        return <CheckboxesField{...other}/>;
      case 'radios' :
        return <RadiosField{...other}/>;
      case 'entity_reference__select' :
      case 'entity_reference__checkboxes' :
      case 'entity_reference__radios' :
        return <EntityReferenceField type={this.props.type.replace('entity_reference__', '')} {...other}/>;
      case 'file' :
        return <FileField{...other}/>;
      case 'fieldset' :
        return <FieldsetElement{...other}/>;
      case 'submit' :
        return <SubmitField{...other}/>;
      case 'SubmitButton' :
        return <SubmitButton{...other}/>;
      default :
        return (
          this.props.content
        );
    }
  };

  _getDescription = () => {
    return this.props.description
      ? <small className={'help-block'} dangerouslySetInnerHTML={{__html : this.props.description}}></small>
      : '';
  };

  render() {
    this.props.type = this.props.type || '';
    this.props.content = this.props.content || '';
    this.props.wrapperAttributes = this.props.wrapperAttributes || {};

    // Class attributes
    this.props.wrapperAttributes.className = this.props.wrapperAttributes.className || '';
    this.props.wrapperAttributes.className = this.props.wrapperAttributes.className.replace(/form-group/ig, '');
    this.props.wrapperAttributes.className = this.props.wrapperAttributes.className.trim();
    this.props.wrapperAttributes.className += ' form-group';
    if (this.props.display) {
      this.props.wrapperAttributes.className += ' ' + this.props.display;
    }
    this.props.wrapperAttributes.className = this.props.wrapperAttributes.className.trim();

    return (
      <div{...this.props.wrapperAttributes}>
        {this._getTitle()}
        {this._getContent()}
        {this._getDescription()}
      </div>
    );
  }

}
