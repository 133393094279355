import React from 'react';

export default class TextareaField extends React.Component {

  constructor(props) {
    super(props);
  }

  handleChange = (e) => {
    // Do not need for textarea
    // e.preventDefault();

    this.props.onChange(e);
  };

  render() {
    this.props.attributes = this.props.attributes || {};

    if (this.props.value) {
      this.props.attributes['value'] = this.props.value;
    }

    if (this.props.required) {
      this.props.attributes['required'] = 'required';
      this.props.attributes['aria-required'] = 'true';
    }

    return (
      <textarea name={this.props.name} id={this.props.name} onChange={this.handleChange} {...this.props.attributes}/>
    );
  }

}
