import React from 'react';
import CheckboxRadioField from './CheckboxRadioField.jsx';

export default class CheckboxesRadiosField extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    let wrapper_class = 'form-checks';
    if (this.props.options_display) {
      wrapper_class += ' ' + this.props.options_display;
    }

    return (
      <div className={wrapper_class}>
        {
          Object.keys(this.props.options).map((optionValue, i) => {
            let isChecked = false;

            if (
              this.props.value
              && typeof this.props.value == 'object'
            ) {
              isChecked = this.props.value.indexOf(optionValue) >= 0;
            }
            else if (typeof this.props.value == 'string') {
              isChecked = optionValue == this.props.value;
            }

            return <CheckboxRadioField
              type={this.props.type}
              label={this.props.options[optionValue]}
              value={optionValue}
              name={this.props.name}
              id={this.props.name + '[' + i + ']'}
              checked={isChecked}
              attributes={this.props.attributes}
              onChange={this.props.onChange}
            />;
          })
        }
      </div>
    );
  }

}
