import React from 'react';
import ReactDOM from 'react-dom';

import svg404 from '../../../svg/404.svg';

class SvgLoadedElement extends React.Component {

  constructor(props) {
    super(props);
  }

  _convertSvgStringToBase64 = (svgString) => {
    return 'data:image/svg+xml;base64,' + window.btoa(svgString);
  };

  _getSvg = () => {
    switch (this.props.svgName) {
      case '404' : return (svg404);
    }
  };

  render() {
    return (
      <div dangerouslySetInnerHTML={{ __html: this._getSvg() }}>
      </div>
    );
  }

}

export default SvgLoadedElement;

const domContainers = document.querySelectorAll('.load-svg');

Object.keys(domContainers).map((index) => {
  let domContainer = domContainers[index];
  let svgName = domContainer.getAttribute('data-name');

  ReactDOM.render(<SvgLoadedElement svgName={svgName}/>, domContainer);
});
