const dropdown = {
  init: (elementHtmlTag, selectorElements = [' > a', ' > span']) => {
    let menuChildrenClass = '[class*=menu--children-nb-]:not(.menu--children-nb--0)';
    let menuChildrenClickElementClass = menuChildrenClass + selectorElements.join(',' + menuChildrenClass);
    let menuChildrenClickElements = elementHtmlTag.querySelectorAll(menuChildrenClickElementClass);

    Object.keys(menuChildrenClickElements).map((index) => {
      let clickElement = menuChildrenClickElements[index];
      let menuChild = clickElement.parentNode;
      let dropdown = new _dropdownPrivate(menuChild, clickElement);
      dropdown.listen();
    });
  }
};

class _dropdownPrivate {
  constructor(dropdownElementHtmlTag, openButton) {
    this.dropdownElementHtmlTag = dropdownElementHtmlTag;
    this.openButton = openButton;
    this.isOpen = false;
  }

  listen() {
    if (!this.isExists(this.dropdownElementHtmlTag)) {
      return;
    }

    this.openButton.addEventListener('click', (e) => {
      e.preventDefault();
      this.toggle();
    });
  }

  isExists(htmlElement) {
    return htmlElement
      && typeof htmlElement != 'undefined';
  }

  toggle() {
    /**
     * Check if valid variable
     * */
    if (!this.isExists(this.dropdownElementHtmlTag)) {
      return;
    }

    this.openAnimation();

    /*
    * Open/Close focused dropdown
    * */
    if (!this.isOpen) {
      document.addEventListener('mousedown', this.outsideClickListener.bind(this));
    }
    else {
      document.removeEventListener('mousedown', this.outsideClickListener);
    }

    this.isOpen = !this.isOpen;
  }

  outsideClickListener(event) {
    if (
      this.dropdownElementHtmlTag
      && !this.dropdownElementHtmlTag.contains(event.target)
      && this.isOpen === true
    ) {
      this.toggle();
    }
  }

  openAnimation() {
    this.dropdownElementHtmlTag.classList.remove(this.isOpen ? 'menu--dropdown-open' : 'menu--dropdown-close');
    this.dropdownElementHtmlTag.classList.add(this.isOpen ? 'menu--dropdown-close' : 'menu--dropdown-open');

    // this.dropdownElementHtmlTag.find('> button')
    this.openButton
      .setAttribute('aria-expanded', this.isOpen ? 'false' : 'true');

    // this.dropdownElementHtmlTag.querySelectorAll(':scope > ul') // => IE... :(
    let dropdownChildren = this.dropdownElementHtmlTag.childNodes;
    Object.keys(dropdownChildren).map((index) => {
      let expandedElement = dropdownChildren[index];

      if (expandedElement.tagName == 'ul') {
        expandedElement.setAttribute('aria-hidden', this.isOpen ? 'true' : 'false');
      }
    });
  }
}

export default dropdown;
