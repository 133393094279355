import React from 'react';
import {format} from 'date-fns';
import {fr} from 'date-fns/locale';

export default class ViewmodeEvent extends React.Component {

  constructor(props) {
    super(props);
  }

  _renderDates(startDate, endDate) {
    startDate = new Date(startDate);
    endDate = new Date(endDate);

    return (
      <div className={'agenda__date'}>
        {this._renderDate(startDate)}
        {
          format(startDate, 'd MMMM yyyy') != format(endDate, 'd MMMM yyyy')
            ? this._renderDate(endDate)
            : ''
        }
      </div>
    );
  }

  _renderDate = (datetime) => {
    return (
      <span className={'date'}>
        <span className={'day'}>{format(datetime, 'd', {locale: fr})}</span>
        <span className={'month'}>{format(datetime, 'MMMM', {locale: fr})}</span>
        <span className={'year'}>{format(datetime, 'yyyy', {locale: fr})}</span>
      </span>
    );
  };

  render() {
    return (
      <div className={'agenda__wrapper'}>
        <a className={'agenda__bloc'} href={this.props.url}>
          <div className={'agenda__left'}>
            {this._renderDates(this.props.start_date, this.props.end_date)}
          </div>
          <div className={'agenda__content'}>
            <div className={'agenda__title'}>{this.props.title}</div>
            <div
              className={'agenda__summary'}
              dangerouslySetInnerHTML={{__html: this.props.summary}}
            >
            </div>
          </div>
        </a>
      </div>
    );
  }

}
